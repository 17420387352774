import React from "react";
import {
  Dialog,
  IconButton,
  Typography,
  Grid,
  Box,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReKyc from "../../assets/images/Login/ReKyc.png";
import ReLogin from "../../assets/images/Login/ReLogin.png";
import Client from "../../assets/images/Login/Client.png";
import SubBroker from "../../assets/images/Login/SubBroker.png";
import WebBranch from "../../assets/images/Login/WebBranch.png";
import WebClient from "../../assets/images/Login/WebClient.png";

export default function LoginDialog({ open, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: 5,
        },
      }}
    >
      <Typography sx={{ m: 0, p: 2 }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Typography>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={12} md={3} xs={12}>
            <Box
              onClick={() =>
                (window.location.href =
                  "https://backoffice.arhamshare.com/WebLogin/index.cfm?Logintype=Branch")
              }
              sx={{
                backgroundColor: "#E9F3F2",
                p: 1,
                borderRadius: 5,
                border: "1px solid #CACACA",
                width: "80%",
                height: "80px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",

                "&:hover": {
                  backgroundColor: "#396A62",
                  color: "white",
                  "& img": {
                    filter: "brightness(0) invert(1)",
                  },
                },
              }}
            >
              <img src={WebBranch} style={{ width: "35px" }} />
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontWeight: 600,
                  fontSize: "14px",
                  pt: 1,
                }}
              >
                Web Branch
              </Typography>
            </Box>
          </Grid>

          <Grid item sm={12} md={3} xs={12}>
            <Box
              onClick={() =>
                (window.location.href =
                  "https://backoffice.arhamshare.com/WebClient/")
              }
              sx={{
                backgroundColor: "#E9F3F2",
                p: 1,
                borderRadius: 5,
                border: "1px solid #CACACA",
                width: "80%",
                height: "80px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",

                "&:hover": {
                  backgroundColor: "#396A62",
                  color: "white",
                  "& img": {
                    filter: "brightness(0) invert(1)",
                  },
                },
              }}
            >
              <img src={WebClient} style={{ width: "35px" }} />
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontWeight: 600,
                  fontSize: "14px",
                  pt: 1,
                }}
              >
                Web Client
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={12} md={3} xs={12}>
            <Box
              onClick={() =>
                (window.location.href = "https://connect.arhamshare.com/")
              }
              sx={{
                backgroundColor: "#E9F3F2",
                p: 1,
                borderRadius: 5,
                border: "1px solid #CACACA",
                width: "80%",
                height: "80px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",

                "&:hover": {
                  backgroundColor: "#396A62",
                  color: "white",
                  "& img": {
                    filter: "brightness(0) invert(1)",
                  },
                },
              }}
            >
              <img src={WebClient} style={{ width: "35px" }} />
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontWeight: 600,
                  fontSize: "14px",
                  pt: 1,
                }}
              >
                Web Client
                <br />
                <span style={{ fontSize: "12px" }}>(New Version)</span>
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={12} md={3} xs={12}>
            <Box
              onClick={() =>
                (window.location.href = "https://arhamshare.investwell.app/")
              }
              sx={{
                backgroundColor: "#E9F3F2",
                p: 1,
                borderRadius: 5,
                border: "1px solid #CACACA",
                width: "80%",
                height: "80px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",

                "&:hover": {
                  backgroundColor: "#396A62",
                  color: "white",
                  "& img": {
                    filter: "brightness(0) invert(1)",
                  },
                },
              }}
            >
              <img src={Client} style={{ width: "35px" }} />
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontWeight: 600,
                  fontSize: "14px",
                  pt: 1,
                }}
              >
                MF Client Login
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2}  py={2}>
          <Grid item sm={12} xs={12} md={3}>
            <Box
              onClick={() =>
                (window.location.href =
                  "https://backoffice.arhamshare.com/WebLogin/index.cfm?Logintype=SUBBRANCH")
              }
              sx={{
                backgroundColor: "#E9F3F2",
                p: 1,
                borderRadius: 5,
                border: "1px solid #CACACA",
                width: "80%",
                height: "80px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",

                "&:hover": {
                  backgroundColor: "#396A62",
                  color: "white",
                  "& img": {
                    filter: "brightness(0) invert(1)",
                  },
                },
              }}
            >
              <img src={SubBroker} style={{ width: "35px" }} />
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontWeight: 600,
                  fontSize: "14px",
                  pt: 1,
                }}
              >
                Sub Brokers
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={12} xs={12} md={3}>
            <Box
              onClick={() =>
                (window.location.href =
                  "https://backoffice.arhamshare.com/WebLogin/index.cfm?Logintype=RO")
              }
              sx={{
                backgroundColor: "#E9F3F2",
                p: 1,
                borderRadius: 5,
                border: "1px solid #CACACA",
                width: "80%",
                height: "80px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",

                "&:hover": {
                  backgroundColor: "#396A62",
                  color: "white",
                  "& img": {
                    filter: "brightness(0) invert(1)",
                  },
                },
              }}
            >
              <img src={ReLogin} style={{ width: "35px" }} />
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontWeight: 600,
                  fontSize: "14px",
                  pt: 1,
                }}
              >
                Re-Login
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={12} xs={12} md={3}>
            <Box
              onClick={() =>
                (window.location.href = "https://web.arhamshare.com/login")
              }
              sx={{
                backgroundColor: "#E9F3F2",
                p: 1,
                borderRadius: 5,
                border: "1px solid #CACACA",
                width: "80%",
                height: "80px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",

                "&:hover": {
                  backgroundColor: "#396A62",
                  color: "white",
                  "& img": {
                    filter: "brightness(0) invert(1)",
                  },
                },
              }}
            >
              <img src={ReKyc} style={{ width: "35px" }} />
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontWeight: 600,
                  fontSize: "14px",
                  pt: 1,
                }}
              >
                Re-KYC
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
