import React from "react";
import { Grid, Typography } from "@mui/material";
import CircleWithText from "./CircleWithText";

const benefits = [
  { text: "Innovative Technology", color: "#E3EBC1", borderColor: "#9FA976" },
  {
    text: "Exceptional Customer Service",
    color: "#D4E8E4",
    borderColor: "#6F978F",
  },
  { text: "Educational Resources", color: "#FFEFE2", borderColor: "#C7A080" },
  { text: "Comprehensive Analytics", color: "#EFEDFD", borderColor: "#8782A9" },
];

const BenefitsLayout = () => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={4} md={3} sm={2}>
        <CircleWithText
          text={benefits[0].text}
          color={benefits[0].color}
          borderColor={benefits[0].borderColor}
        />
      </Grid>
      <Grid item xs={4} md={4} sm={4}>
        <Typography
          sx={{
            fontSize: { xs: "20px", md: "26px" },
            fontWeight: 600,
            fontFamily: "Ubuntu",
            textAlign: "center",
            mt: 2,
          }}
        >
          <span style={{ color: "#2D5A53" }}> Benefits of Using </span><br/>
          <span style={{ color: "#666C4A" }}>Arham's API</span>
        </Typography>
      </Grid>
      <Grid item xs={4} md={2} sm={3}>
        <CircleWithText
          text={benefits[1].text}
          color={benefits[1].color}
          borderColor={benefits[1].borderColor}
        />
      </Grid>
      <Grid container justifyContent="center" alignItems="center" sx={{ pl: { xs: 5, md: 7 } }} pt={2}>
        <Grid item xs={6} md={3} sm={3}>
          <CircleWithText
            text={benefits[2].text}
            color={benefits[2].color}
            borderColor={benefits[2].borderColor}
          />
        </Grid>
        <Grid item xs={6} md={4} sm={3}>
          <CircleWithText
            text={benefits[3].text}
            color={benefits[3].color}
            borderColor={benefits[3].borderColor}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BenefitsLayout;
