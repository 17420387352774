import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

function AnimatedImage({ src, style ,className}) {
  const { ref, inView } = useInView({ triggerOnce: false, threshold: 0.1 });

  return (
    <motion.img
      ref={ref}
      src={src}
      style={style}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
      transition={{ duration: 0.5 }}
      className={className}
    />
  );
}

export default AnimatedImage;
