import "./App.css";

import AppRoutes from "./Routes/AppRoutes";
import { BlogProvider } from "./Blog/BlogProvider";
function App() {
  return (
    <div className="App">
      <BlogProvider>
        <AppRoutes />
      </BlogProvider>
    </div>
  );
}

export default App;
