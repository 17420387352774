import React, { useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import Header from "../Component/HomePage/Header";
import Fotter from "../Component/Footer";
import DownloadIcon from "../assets/images/IPOs/Download.png";

function Others() {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(null);

  const otherData = [
    {
      title: "ARHAM LOCAL-4 SRVR REG",
    },
    {
      title: "ARHAM LOCAL-1 SRVR REG",
    },
    {
      title: "ARHAM ODIN-1 SRVR REG",
    },
    {
      title: "ARHAM ODIN-4 SRVR REG",
    },
  ];

  const handleBreadcrumbClick = (event, path) => {
    event.preventDefault();
    navigate(path);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <>
      <Header />

      <Grid
        container
        sx={{
          background:
            "linear-gradient(180deg, #ffffff 0%, #c1d8d4 0%, #ffffff 40%)",
          px: { xs: 4, md: 20 },
        }}
      >
        <Grid container pt={6}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ margin: "auto 0", textAlign: "center" }}
          >
            <Typography
              sx={{
                fontSize: "55px",
                fontFamily: "Ubuntu",
                fontWeight: 500,
              }}
            >
              Others
            </Typography>
            {/* <Breadcrumbs
              aria-label="breadcrumb"
              separator=">"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Link
                color="inherit"
                href="/"
                onClick={(event) => handleBreadcrumbClick(event, "/")}
                sx={{
                  cursor: "pointer",
                  textDecoration: "none",
                  fontFamily: "Ubuntu",
                  fontWeight: 500,
                  fontSize: "25px",
                  color: "#3D3D3D",
                }}
              >
                Home
              </Link>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontWeight: 400,
                  fontSize: "25px",
                  color: "#3D3D3D",
                }}
              >
                Others
              </Typography>
            </Breadcrumbs> */}
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} sx={{ pt: 6 }}>
          <Typography
            sx={{
              fontSize: "35px",
              fontFamily: "Ubuntu",
              fontWeight: 500,
              textAlign: "left",
              color: "#396A62",
            }}
          >
            {/* Others */}
          </Typography>
        </Grid>

        <Grid container spacing={2}>
          {[
            "Registry",
            "Corporate Governance- Arham Share Consultants Pvt Ltd",
            "Corporate Governance- Arham Commodity Brokers Pvt Ltd",
          ].map((category) => (
            <Grid item key={category}>
              <Button
                variant="contained"
                onClick={() => handleCategoryClick(category)}
                sx={{
                  backgroundColor:
                    selectedCategory === category ? "#396A62" : "#ffffff",
                  color: selectedCategory === category ? "#ffffff" : "#396A62",
                  border: "1px solid #396A62",
                  fontFamily: "Ubuntu",
                  fontSize: "13px",
                  fontWeight: 500,
                  "&:hover": {
                    backgroundColor: "#396A62",
                    color: "#ffffff",
                  },
                }}
              >
                {category}
              </Button>
            </Grid>
          ))}
        </Grid>

        <Grid container spacing={2} sx={{ pt: 8 }}>
          {otherData.map((file, index) => (
            <Grid item xs={12} sm={2} md={3} key={index}>
              <Box
                sx={{
                  textAlign: "center",
                  border: "1px solid #E0E0E0",
                  borderRadius: "8px",
                  height: "150px",
                  p: 2,
                  "&:hover": {
                    backgroundColor: "#E0E0E0",
                  },
                }}
              >
                <img src={DownloadIcon} alt={`${file.title} Download`} />
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: "14px",
                    color: "#3D3D3D",
                    mt: 2,
                  }}
                >
                  {file.title}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Fotter />
    </>
  );
}

export default Others;
