import React from "react";
import { Button, Grid, Typography, Box } from "@mui/material";
import Fotter from "../Component/Footer";

const doList = [
  {
    title: "Avoid unregistered intermediaries:",
    description:
      "Do not transact with unregistered brokers, sub-brokers, or intermediaries.",
  },
  {
    title: "Understand agreements:",
    description:
      "Carefully read and understand all documents before signing with any intermediary.",
  },
  {
    title: "File complaints correctly:",
    description:
      "Submit grievances or arbitration applications to the appropriate Regional Arbitration Centre.",
  },
  {
    title: "Be aware of complaint resolution process:",
    description:
      "Understand the timeframes for complaint resolution through arbitration and IGRC.",
  },
  {
    title: "Retain transaction documents:",
    description:
      "Keep all transaction-related documents, even if dealing with known parties.",
  },
  {
    title: "Beware of unrealistic promises:",
    description: "Avoid investments promising exceptionally high returns.",
  },
  {
    title: "Verify company claims:",
    description:
      "Do not rely solely on government approvals displayed by companies.",
  },
  {
    title: "Ignore market rumors:",
    description: "Avoid making investment decisions based on hearsay or tips.",
  },
  {
    title: "Protect your Demat account:",
    description: "Do not share your Demat transaction slip book with anyone.",
  },
  {
    title: "Acknowledge investment risks:",
    description:
      "Understand the potential risks associated with your investments.",
  },
  {
    title: "Be cautious of guarantees:",
    description:
      "Avoid investments with guaranteed returns through post-dated cheques.",
  },
  {
    title: "Seek help when needed:",
    description: "Contact relevant authorities if you encounter any issues.",
  },
  {
    title: "Avoid impulsive decisions:",
    description:
      "Do not make investment choices based solely on advertisements or media reports.",
  },

  {
    title: "Conduct independent analysis:",
    description: "Do not blindly copy investment decisions of others.",
  },
];

function DontInvestor() {
  return (
    <>
      <Box
        sx={{
          p: 4,
          background:
            " linear-gradient(180deg, #ffffff 0%, #F1F3E5 0%, #ffffff 80%)",
          px: { xs: 4, md: 4, lg: 14 },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#697523",
                "&:hover": {
                  backgroundColor: "#697523",
                },
                textTransform: "none",
                fontFamily: "Ubuntu",
                borderRadius: "8px",
                fontSize: "large",
                width: "100%",
                maxWidth: "300px",
                margin: "0 auto",
                display: "block",
                my: 2,
              }}
            >
              <span style={{ fontSize: "20px" }}>Don'ts for Investors</span>
            </Button>
          </Grid>
          {doList.map((item, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Box sx={{ display: "flex", alignItems: "flex-start", mb: 4 }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "Ubuntu",
                    marginRight: "8px",
                    color: "#697523",
                    fontSize: "2.5rem",
                    lineHeight: "2rem",
                  }}
                >
                  •
                </Typography>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Ubuntu",
                      fontWeight: 600,
                      fontSize: "16px",
                      textAlign: "left",
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Ubuntu",
                      textAlign: "left",
                    }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Fotter />
    </>
  );
}

export default DontInvestor;
