import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  styled,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useBlog } from "./BlogProvider";

// Custom styled text field for the subscription input
const CustomTextField = styled(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid white !important",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      border: "1px solid white !important",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid white !important",
      borderRadius: "8px",
    },
  },
  "& .MuiInputLabel-root": {
    color: "white",
    fontSize: "15px",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "white",
    fontSize: "15px",
  },
  "& .MuiInputBase-input": {
    color: "white",
    fontSize: "15px",
  },
}));

// Main component function
function LatestBlog() {
  const { blogs, setBlogs } = useBlog();

  useEffect(() => {
    fetch("http://192.168.119.192:3020/arham_web/v1/fetch-blogs/", {
      headers: {
        source: "arham_web",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setBlogs(data.message || []);
      })
      .catch((error) => console.error("Error fetching blogs:", error));
  }, [setBlogs]);

  // Split blogs into two parts
  const firstTwoBlogs = blogs.slice(0, 2);
  const remainingBlogs = blogs.slice(2);

  return (
    <Grid sx={{ px: 2 }}>
      {/* Header Section */}
      <Grid container justifyContent="center">
        <Grid item xs={12} md={12}>
          <Typography
            sx={{
              fontSize: "35px",
              fontFamily: "Ubuntu",
              fontWeight: 500,
              pt: 6,
              textAlign: "center",
            }}
          >
            Explore Our <span style={{ color: "#396A62" }}>Latest Blogs</span>
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Ubuntu",
              pt: 1,
              textAlign: "center",
            }}
          >
            Welcome to our blog hub, where we share insights, stories, and
            trends in the world of finance and <br />
            beyond. Dive in to explore expert analyses, tips, and the latest
            updates.
          </Typography>
        </Grid>
      </Grid>

      {/* First Two Blog Posts */}
      <Grid sx={{ pt: 8 }}>
        <Grid container spacing={2} columnGap={1} rowGap={1}>
          {firstTwoBlogs.length > 0 ? (
            firstTwoBlogs.map((blog) => (
              <Grid
                item
                xs={12}
                md={5.9}
                key={blog.blog_id}
                sx={{
                  display: "flex",
                  border: "1px solid #CECECE",
                  borderRadius: 10,
                  overflow: "hidden",
                  alignItems: "center",
                }}
              >
                <Box sx={{ flexBasis: "50%", textAlign: "center" }}>
                  <img
                    src={`data:image/png;base64,${blog.file_data}`}
                    alt={blog.blog_header}
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      padding: "15px",
                    }}
                  />
                </Box>

                <Box sx={{ textAlign: "left", p: 4 }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "#396A62",
                      backgroundColor: "#DBE7E5",
                      borderRadius: "5px",
                      padding: "2px 8px",
                      display: "inline-block",
                    }}
                  >
                    {new Date(blog.created_at).toLocaleDateString("en-US", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Ubuntu",
                      fontWeight: 600,
                      color: "#000",
                      mt: 1,
                    }}
                  >
                    {blog.blog_header}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontFamily: "Ubuntu",
                      color: "#7C7C7C",
                      my: 1,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: `${blog.blog_content.slice(0, 100)}...`,
                    }}
                  />

                  <Typography
                    component={Link}
                    to={`/blog/${blog.blog_id}`}
                    state={{ blog }} // Pass the selected blog data
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Ubuntu",
                      color: "#396A62",
                      fontWeight: 500,
                      textDecoration: "none",
                      textTransform: "none",
                    }}
                  >
                    Read More →
                  </Typography>
                </Box>
              </Grid>
            ))
          ) : (
            <Typography sx={{ textAlign: "center", width: "100%" }}>
              No blogs available.
            </Typography>
          )}
        </Grid>
      </Grid>

      {/* Subscription Section */}
      <Grid
        container
        sx={{
          background: "#38655E",
          px: { xs: 4, md: 2, lg: 6 },
          mt: 8,
          borderRadius: 5,
          py: 6,
        }}
      >
        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              fontSize: "35px",
              fontFamily: "Ubuntu",
              fontWeight: 500,
              color: "white",
              textAlign: "left",
            }}
          >
            Don’t miss any{" "}
            <span style={{ color: "#E0F199" }}>
              Blogs <br />
              from Affiliate
            </span>
          </Typography>
          <Typography
            sx={{
              fontSize: "13px",
              fontFamily: "Ubuntu",
              color: "white",
              pt: 2,
              textAlign: "left",
            }}
          >
            Welcome to our blog hub, where we share insights, stories, and
            <br />
            trends in the world of finance and beyond. Dive in to explore
            <br />
            expert analyses, tips, and the latest updates.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} py={4}>
          <Typography
            sx={{
              fontSize: "30px",
              fontFamily: "Ubuntu",
              fontWeight: 500,
              color: "white",
            }}
          >
            Subscribe to our newsletter
          </Typography>
          <Typography sx={{ pt: 1 }}>
            <CustomTextField
              type="email"
              name="email"
              label="Your Email Address"
              variant="outlined"
              fullWidth
              id="outlined-size-small"
              size="small"
              sx={{ width: "75%" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ArrowForwardIcon
                      style={{
                        color: "#396A62",
                        backgroundColor: "#E0F199",
                        borderRadius: "4px",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Typography>
        </Grid>
      </Grid>

      {/* Remaining Blog Posts */}
      <Grid sx={{ pt: 8 }}>
        <Grid container spacing={2} columnGap={1} rowGap={1}>
          {remainingBlogs.length > 0 ? (
            remainingBlogs.map((blog) => (
              <Grid
                item
                xs={12}
                md={5.9}
                key={blog.blog_id}
                sx={{
                  display: "flex",
                  border: "1px solid #CECECE",
                  borderRadius: 10,
                  overflow: "hidden",
                  alignItems: "center",
                }}
              >
                
                <Box sx={{ flexBasis: "50%", textAlign: "center" }}>
                  <img
                    src={`data:image/png;base64,${blog.file_data}`}
                    alt={blog.blog_header}
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      padding: "15px",
                    }}
                  />
                </Box>
                <Box sx={{ textAlign: "left", p: 4 }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "#396A62",
                      backgroundColor: "#DBE7E5",
                      borderRadius: "5px",
                      padding: "2px 8px",
                      display: "inline-block",
                    }}
                  >
                    {new Date(blog.created_at).toLocaleDateString("en-US", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Ubuntu",
                      fontWeight: 600,
                      color: "#000",
                      mt: 1,
                    }}
                  >
                    {blog.blog_header}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontFamily: "Ubuntu",
                      color: "#7C7C7C",
                      my: 1,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: `${blog.blog_content.slice(0, 100)}...`,
                    }}
                  />

                  <Typography
                    component={Link}
                    to={`/blog/${blog.blog_id}`}
                    state={{ blog }}
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Ubuntu",
                      color: "#396A62",
                      fontWeight: 500,
                      textDecoration: "none",
                      textTransform: "none",
                    }}
                  >
                    Read More →
                  </Typography>
                </Box>
              </Grid>
            ))
          ) : (
            <Typography sx={{ textAlign: "center", width: "100%" }}>
              No blogs available.
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LatestBlog;
