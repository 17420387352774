import React, { useState } from "react";
import {
  Box,
  Toolbar,
  Button,
  IconButton,
  Grid,
  ThemeProvider,
  createTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArhamLogo from "../../assets/images/logo/logo.png";
import "../../style/style.css";
import NavDrawer from "../Drawer/NavDrawer";
import { Link, useLocation } from "react-router-dom";
import LoginDialog from "./LoginDialog";

const theme = createTheme();

export default function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const [isSignIn, setIsSignIn] = useState(true);
  const isMobile = useMediaQuery("(max-width:1280px)");
  const location = useLocation();

  const navItems = [
    { label: "Home", path: "/" },
    { label: "Services", path: "/services" },
    { label: "Become Partner", path: "/become-partner" },
    { label: "Investor's Charter", path: "/investor's-Charter" },
    { label: "Downloads", path: "/download" },
    { label: "Contact Us", path: "/contact-us" },
  ];

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLoginClick = () => {
    setLoginDialogOpen(true);
  };

  const handleDialogClose = () => {
    setLoginDialogOpen(false);
  };

  const handleOpenDematClick = () => {
    window.location.href = "https://ekyc.arhamshare.com/";
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          transition: "background-color 0.3s ease, box-shadow 0.3s ease",
          background:
            "linear-gradient(180deg, #ffffff 0%, #c1d8d4 0%, #c1d8d4 100%)",
          px: { xs: 4, md: 4, lg: 18 },
          py: 1,
          // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
        alignItems="center"
      >
        <Grid item xs={6} sm={6} md={2}>
          <Link to="/">
            <img
              src={ArhamLogo}
              alt="Logo"
              style={{
                width: "290px",
                paddingTop: "8px",
              }}
              className="arham-logo"
            />
          </Link>
        </Grid>

        <Grid
          item
          xs={6}
          sm={6}
          md={10}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {isMobile ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          ) : (
            navItems
              .filter((item) => item.path !== "/" || location.pathname !== "/")
              .map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    position: "relative",
                    textAlign: "center",
                    fontFamily: "Ubuntu",
                    mx: 1.5,
                  }}
                >
                  {item.label === "Investor's Charter" && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "-10px",
                        left: "95%",
                        transform: "translateX(-50%)",
                        backgroundColor: "#396A62",
                        color: "#fff",
                        padding: "2px 6px",
                        borderRadius: "4px",
                        fontSize: "8px",
                        fontWeight: "bold",
                        fontFamily: "Ubuntu",
                      }}
                    >
                      New
                    </Box>
                  )}
                  <Typography
                    component={Link}
                    to={item.path}
                    sx={{
                      textDecoration: "none",
                      fontFamily: "Ubuntu",
                      fontWeight:500,
                      // fontSize:"18px",
                      position: "relative",
                      display: "inline-block",
                      transition:
                        "transform 0.2s ease-in-out, color 0.2s ease-in-out",
                      transform:
                        location.pathname === item.path
                          ? "translateY(2px)"
                          : "none",
                      color:
                        location.pathname === item.path ? "#396A62" : "black",
                      "&:hover": {
                        transform: "translateY(2px)",
                      },
                    }}
                  >
                    {item.label}
                  </Typography>
                </Box>
              ))
          )}
          {!isMobile && (
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "#396A62",
                fontFamily: "Ubuntu",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#0B2A29",
                },
                ml: 2,
              }}
              className="header-btn"
              onClick={handleLoginClick}
            >
              Login
            </Button>
          )}
        </Grid>

        <NavDrawer
          drawerOpen={drawerOpen}
          handleDrawerToggle={handleDrawerToggle}
          navItems={navItems}
          isSignIn={isSignIn}
        />
      </Grid>

      <LoginDialog open={loginDialogOpen} onClose={handleDialogClose} />
    </ThemeProvider>
  );
}
