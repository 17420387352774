import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import icon from "../../assets/images/logo/Icon.png";
import icon1 from "../../assets/images/logo/Icon1.png";
import icon2 from "../../assets/images/logo/Icon2.png";
import icon3 from "../../assets/images/logo/Icon3.png";
import icon4 from "../../assets/images/logo/Icon4.png";
import icon5 from "../../assets/images/logo/Icon5.png";
import icon6 from "../../assets/images/logo/Icon6.png";
import icon7 from "../../assets/images/logo/Icon7.png";
import icon8 from "../../assets/images/logo/Icon8.png";

import HoverGridItem from "./HoverGridItem";

function Index() {
  return (
    <div>
      <Grid
        container
        sx={{
          backgroundColor: "#F8FAEF",
          px: { xs: 4, md:20 },
          py: { xs: 2, md: 4, lg: 4 },
          mt: 12,
        
        }}
      >
        <Grid container >
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontSize: "38px",
                    fontWeight: 500,
                    fontFamily: "Ubuntu",
                    py:4
                  }}
                >
                  <span>Our Service</span>
                </Typography>
              </Grid>

              {[
                {
                  icon: icon,
                  title: "Margin Trading Facility",
                  description:
                    "Leverage your trading potential with our flexible margin trading facility, offering competitive rates.",
                },
                {
                  icon: icon1,
                  title: "Third Party Product Distribution",
                  description:
                    "Wide range of third-party financial products, including mutual funds, insurance, and bonds.",
                },
                {
                  icon: icon2,
                  title: "DP Services",
                  description:
                    "Comprehensive depository participant services for secure and efficient management of your securities.",
                },
                {
                  icon: icon3,
                  title: "Multiple Platforms For Trading & Investment",
                  description:
                    "We offer four trading platforms: Mobile app, Web trader, EXE terminal, and API Algo desk.",
                },
                {
                  icon: icon4,
                  title: "Online Client Onboarding in 30mins",
                  description:
                    "Quick and seamless online client onboarding process, complete in just 30 minutes.",
                },
                {
                  icon: icon5,
                  title: "Dedicated Retail HNIs Desk",
                  description:
                    "Exclusive support desk catering specifically to High Net Worth Individuals (HNIs) for personalized service.",
                },
                {
                  icon: icon6,
                  title: "Personalized FPI Investment Solutions",
                  description:
                    "Personalized FPI Investment Solutions provide tailored strategies for your financial goals and risk tolerance.",
                },
                {
                  icon: icon7,
                  title: "Dedicated NRI Desk",
                  description:
                    "Our Dedicated NRI Desk offers expert guidance, simplifying cross-border investments with personalized support.",
                },
                // {
                //   icon: icon8,
                //   title: "Delta Desk",
                //   description:
                //     "Advanced trading desk providing strategic insights and trade execution for complex market scenarios.",
                // },
              ].map((service, index) => (
                <Grid item xs={12} md={4} key={index} pb={4}>
                  <Box
                    sx={{
                      border: "1px solid #e0e0e0",
                      borderRadius: "30px",
                      padding: "10px",
                      height: "100%",
                      width: "90%",

                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      // justifyContent: "center",
                    }}
                  >
                    <HoverGridItem>
                      <img
                        src={service.icon}
                        alt="Logo"
                        style={{
                          height: "70px",
                          width: "70px",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: 600,
                          pt: 2,
                          fontFamily: "Ubuntu",
                          textAlign: "center",
                        }}
                      >
                        {service.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          textAlign: "center",
                          pt: 2,
                          fontFamily: "Ubuntu",
                          color: "#3D3D3D",
                        }}
                      >
                        {service.description}
                      </Typography>
                    </HoverGridItem>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Index;
