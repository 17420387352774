import React from "react";
import Header from "../Component/HomePage/Header";
import { Grid, Typography, Box, Breadcrumbs, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Product from "../assets/images/Product-Services/product.png";
import Services from "./Services";
import Products from "./Products";

const Index = () => {
  const navigate = useNavigate();

  const handleBreadcrumbClick = (event, path) => {
    event.preventDefault();
    navigate(path);
  };

  return (
    <>
      <Header />

      <Grid
        container
        sx={{
          background:
            " linear-gradient(180deg, #ffffff 0%, #c1d8d4 0%, #ffffff 40%)",
          px: { xs: 4, md: 20 },
        }}
      >
        <Grid container pt={6} spacing={2}>
          <Grid item xs={12} md={6} sx={{ margin: "auto 0" }}>
            <Typography
              sx={{
                fontSize: { md: "50px", xs: "35px" },
                fontFamily: "Ubuntu",
                fontWeight: 500,
                // pt: 4,
                textAlign: "left",
              }}
            >
              Products & Services
            </Typography>
            {/* <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Link
                color="inherit"
                href="/"
                onClick={(event) => handleBreadcrumbClick(event, "/")}
                sx={{
                  cursor: "pointer",
                  textDecoration: "none",
                  fontFamily: "Ubuntu",
                  fontWeight: 500,
                  fontSize: { md: "25px", xs: "20px" },
                  color: "#3D3D3D",
                }}
              >
                Home
              </Link>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontWeight: 400,
                  fontSize: { md: "25px", xs: "20px" },

                  color: "#3D3D3D",
                }}
              >
                Products & Services
              </Typography>
            </Breadcrumbs> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={Product} style={{ width: "60%" }} />
          </Grid>
        </Grid>

        <Services />
      </Grid>
      <Products />
    </>
  );
};

export default Index;
