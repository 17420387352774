import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import Insurance from "../assets/images/Product-Services/insurance.png";
import Pms from "../assets/images/Product-Services/pms.png";
import Ipo from "../assets/images/Product-Services/ipo.png";
import Bond from "../assets/images/Product-Services/bond.png";
import Fotter from "../Component/Footer";

function Products() {
  const products = [
    {
      image: Insurance,
      title: "Insurance",
      description: ` Insurance acts like financial safety nets. They provide a payout to your loved ones if you pass away, helping them manage financially during a difficult time. Term life offers coverage for a specific period at a lower cost, whole life insurance provides lifelong coverage but typically comes with a higher premium. Have a chat with us to see which option best suits your needs and protects your family's future.<br/>
       Filling the page with insurance suggestions that is relatable to normal people, this will definitely catch some attention of customers seeking for insurance.
    `,
    },
    {
      image: Pms,
      title: "Portfolio Management Services (PMS)",
      description:
        "Arham Share has partnered with many PMS providers, providing a VIP lane for your investments. If your investment is higher, PMS provides you with a professional manager who creates a personalized portfolio based on your goals and risk tolerance. They actively manage your investments, trying to maximize profits while mitigating risk. It's a premium service, but Arham Share can help you get through the procedure successfully.",
    },
    {
      image: Ipo,
      title: "IPOs",
      description: `Initial Public Offering (IPO) refers to a company's stock market debut. Until an IPO, a firm is private, which means its shares are exclusively owned by a small number of people. An IPO is a company's first public offering of shares. This helps them to raise a significant amount of money to expand their firm. It's also an opportunity for everyone to invest in a company that we feel has promise. Consider purchasing a small portion of a firm in the hopes that it will succeed in the future.<br/>
           Let's go into the realm of Initial Public Offerings (IPOs). Here's a list of future, current, and closed IPOs, along with their listing gains (if any).`,
    },
    {
      image: Bond,
      title: "Bonds",
      description: `Arham Shares provides a variety of bond alternatives on the Indian stock market. The RBI issues government bonds, which give the utmost security by guaranteeing the return on your primary investment. These are perfect for folks who value safety and consistent income, particularly as they approach retirement.<br/>
       Looking for a possibly greater return? Arham Shares can help you navigate the world of corporate bonds. These pay greater interest rates than government bonds but carry various levels of credit risk depending on the issuing corporation. Our specialist staff can assist you in analyzing credit ratings and selecting bonds that are appropriate for your risk tolerance and investment objectives. Arham Shares can help you establish a safe and lucrative future with bonds.`,
    },
  ];

  return (
    <>
      <Grid
        sx={{
          background:
            "linear-gradient(180deg, #ffffff 0%, #F1F3E5 0%, #ffffff 100%)",
          pt: 8,
          mt: 8,
          // pb: 8,
        }}
      >
        <Grid
          container
          sx={{
            px: { xs: 4, md: 20 },
          }}
        >
          <Grid item xs={12} sx={{ textAlign: "center", mb: 4 }}>
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                fontWeight: 500,
                fontSize: {md:"35px",xs:"35px"},
                color: "#697523",
              }}
            >
              Our Products
            </Typography>
          </Grid>
          {products.map((product, index) => (
            <Grid key={index} container item xs={12} spacing={2} sx={{ mb: 4 }}>
              <Grid item xs={12}>
                {index > 0 && (
                  <hr
                    style={{
                      border: "1px solid #BFC78E",
                      marginBottom: "1rem",
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={5} sm={1}>
                <img
                  src={product.image}
                  alt={product.title}
                  style={{ width: "100%", height: "auto" }}
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontWeight: 500,
                    fontSize: "25px",
                    color: "#3D3D3D",
                    textAlign: "left",
                    mt: 1,
                  }}
                >
                  {product.title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: "14px",
                    color: "#3D3D3D",
                    textAlign: "left",
                    mt: 1,
                  }}
                >
                  <p
                    dangerouslySetInnerHTML={{ __html: product.description }}
                  />
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Fotter />
    </>
  );
}

export default Products;
