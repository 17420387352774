import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Box,
  Pagination,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const faqData = [
  {
    question:
      "What documents are needed to open a demat account with Arham Share?",
    answer:
      "To open a Demat account, you’ll need some essential documents such as your PAN card, Aadhaar card, and a bank account for basic equity cash transactions.",
  },

  {
    question: "How to modify/add a bank account at Arham Share?",
    answer: (
      <>
        To modify or add your bank account, log in to your back office, navigate
        to "My Account," and select "Bank Addition." Fill in the required
        details, click "Verify," and after entering the OTP, your request will
        be submitted.
      </>
    ),
  },
  {
    question: "How to change your default bank account?",
    answer: (
      <>
        To change your default bank account, log in to your back office,
        navigate to "My Account," and click on "Modify Bank Settings.
      </>
    ),
  },
  {
    question: "What are the payment methods to add funds in a trading account?",
    answer: (
      <>
        There are currently three ways to add funds to your Demat account:{" "}
        <br />- For small transactions up to ₹1 lakh, you can use UPI. <br />-
        For larger deposits, opt for net banking.
        <br /> - Alternatively, you can use NEFT; our bank details are available
        on our website.
      </>
    ),
  },
  {
    question: "How can I add segments in my trading account?",
    answer: (
      <>
        To add segments, log in to your back office (Link), go to "My Account,"
        and select "Segment Addition." From there, choose the segment you want
        to activate and submit the necessary proof.
      </>
    ),
  },
  {
    question:
      "How to activate F&O after opening my demat account at Arham Share?",
    answer:
      "To activate F&O, you can select the desired segment while opening your Demat account with Arham Share. If your account was opened without F&O, you can activate it later through the Arham Share Back Office login.",
  },
  {
    question: "How to login to my back office account?",
    answer: (
      <>
        First, visit connect.arhamshare.com and enter your User ID and Password.
        (Please note that this password is different from your App password). If
        you haven't set a password yet, you can create a new one by selecting
        "Forgot Password."
      </>
    ),
  },
  {
    question: "How to apply for an IPO at Arham Share?",
    answer: (
      <>
        At Arham Share, you can apply for an IPO in two ways: <br />
        1. Through the Back Office (Link). <br />
        2. Via the Trado App.
      </>
    ),
  },
  {
    question: "What is SLBM?",
    answer:
      "SLBM stands for Stock Lending and Borrowing Mechanism. It is a service that allows investors to lend their shares to others for a fee. Borrowers use these shares for purposes such as short selling or fulfilling delivery obligations.",
  },
  {
    question: "How can we Pledge/Unpledge the stocks?",
    answer: "",
  },
  {
    question:
      "How much time will it take to get the funds transferred from the trading account to my bank account?",
    answer:
      "A withdrawal request from your trading account typically takes 24 hours to process.",
  },
  {
    question:
      "How can I transfer my shares from another broker to Arham Share?",
    answer: (
      <>
        To transfer your shares from another broker to Arham Share, first obtain
        the CMR report from Arham Share. Then, initiate the transfer using a
        Delivery Instruction Slip or through your existing broker's web
        platform, referring to the CMR report. The transfer process typically
        takes 2-3 business days.
      </>
    ),
  },
  {
    question: "How can I apply for any buyback offer from Arham Share?",
    answer: (
      <>
        To apply for a buyback offer, log in to Arham Share’s back office with
        your credentials. Navigate to "Corporate Actions," where you will find a
        list of buyback details. Select the relevant offer and proceed with the
        application.
      </>
    ),
  },
  {
    question: "What are the documents needed to open a HUF account?",
    answer: (
      <>
        To open an HUF account, you will need the following documents: the HUF’s
        PAN card, the Karta’s ID and address proof, an HUF declaration, and
        passport-sized photographs of the Karta.
      </>
    ),
  },
  {
    question: "How can I open a physical demat account with Arham Share?",
    answer: (
      <>
        To open a physical Demat account with Arham Share, visit the nearest
        Arham Share branch, complete the account opening form, and submit it
        along with your KYC documents (ID proof, address proof, PAN card) and
        passport-sized photographs. Our relationship executive will assist you
        throughout the process.
      </>
    ),
  },

  {
    question: "What is the cut-off time for submitting pledge requests?",
    answer: <>The cut-off time for submitting a pledge is 4:00 PM.</>,
  },
  {
    question: "Who can execute a F&O trade?",
    answer: (
      <>
        Anyone with an account at Arham Share who has activated the F&O segment
        by submitting the required documents can place trades in the F&O
        segment.
      </>
    ),
  },
  {
    question: "When will the buyer receive the shares that are purchased?",
    answer: (
      <>
        Shares will be credited to your demat account on T+1 day after the
        purchase. Here, T represents the day the shares are bought, and +1
        indicates that the shares will be credited on the next trading day.
      </>
    ),
  },

  {
    question: "How can I set price alerts for stocks?",
    answer: (
      <>
        You can set price alerts for stocks using the Trado App. Simply navigate
        to the stock page, click on the timer icon, choose your preferences, and
        set the alerts to be notified.
      </>
    ),
  },
  {
    question: "What is a settlement holiday?",
    answer: (
      <>
        A settlement holiday occurs when the market is active, but the
        depositories, such as CDSL or NSDL, are closed. During this time, you
        can buy or sell shares, but the transactions will not be settled into
        your demat account because the depositories are not processing
        settlements. You can find the list of settlement holidays on our
        financial calendar page.
      </>
    ),
  },
  {
    question: "How to place a stock order?",
    answer: (
      <>
        Placing a stock order is straightforward: open the Trado App, select the
        stock you wish to buy, and place your order. You can also set a
        stop-loss, choose to buy at market value, or specify a custom buying
        price.
      </>
    ),
  },
  {
    question: "How do I square off open positions?",
    answer: (
      <>
        First, open the Trado App and log in with your user details. Click on
        the "Order" tab, then select "Net Position." Within this tab, you’ll
        find the "Square Off" option. Click on it, confirm the details, and
        proceed to square off your positions.
      </>
    ),
  },
  {
    question: "What is the settlement cycle?",
    answer: (
      <>
        The settlement cycle is the period between the trade date and the date
        when the transaction is finalized, involving the transfer of securities
        and payment. In Indian stock exchanges, a T+1 settlement cycle is
        followed, meaning transactions are completed on the next trading day
        after the purchase of shares.
      </>
    ),
  },
  {
    question: "What does market depth mean?",
    answer: (
      <>
        Market depth reveals the level of buying and selling interest in a stock
        at various price levels. It shows how large orders can be executed
        without causing significant price fluctuations.
      </>
    ),
  },
  {
    question: "Can we buy shares on one exchange and sell on another?",
    answer: (
      <>
        Yes, you can buy shares on one exchange and sell them on another using
        Arham Share’s demat account. This capability allows you to take
        advantage of liquidity and market dynamics across both major exchanges
        (BSE and NSE) through a single platform.
      </>
    ),
  },
  {
    question: "What is BTST trading?",
    answer: (
      <>
        BTST trading, which stands for "Buy Today, Sell Tomorrow," involves
        buying shares and selling them the next day, even before they are
        settled into your demat account. This strategy allows you to capitalize
        on short-term price movements without waiting for the usual settlement
        cycle.
      </>
    ),
  },
  {
    question: "What is T-Pin? How do I get it for selling shares?",
    answer: (
      <>
        A T-Pin is a 6-digit code required to authorize the sale of shares. It
        is typically generated through CDSL by entering your BO ID and PAN
        number. Once generated, the T-Pin is valid for 6 months. This code
        provides an extra layer of security for your stocks, ensuring they are
        not transferred without your authorization.
      </>
    ),
  },
  {
    question: "Can we convert the active intraday trade into delivery?",
    answer: (
      <>
        Yes, you can convert an active intraday trade into delivery, but there
        are important considerations. Ensure you convert the position before
        3:15 PM, as conversions are not possible after this time. Additionally,
        verify that you have sufficient funds in your trading account to cover
        the conversion.
      </>
    ),
  },
  {
    question:
      "How long will it take to receive the money after selling the shares?",
    answer: (
      <>
        After selling your shares, you'll receive 80% of the proceeds on the
        same day. Due to the T+1 settlement cycle, you cannot withdraw this
        amount immediately. The remaining 20% will be credited the next day.
        Once the full amount is settled at the end of the day, you can place a
        withdrawal request. The funds will be transferred to you within 24 hours
        of submitting the request.
      </>
    ),
  },
  {
    question: "How to use the option calculator?",
    answer: (
      <>
        Go to the option calculator in the Trado App. Select the scrip and
        option type, then enter the spot price and strike price. Click on
        "Calculate" to view the Delta Greeks values, including Delta, Gamma,
        Theta, Vega, and Rho.
      </>
    ),
  },
  {
    question: "What is F&O trading?",
    answer: (
      <>
        F&O (Futures and Options) trading involves buying and selling derivative
        contracts based on underlying assets like equities, indices, or
        commodities. It allows traders to speculate on market movements, hedge
        against risks, and leverage positions with a relatively small initial
        investment.
      </>
    ),
  },
  {
    question: "How to trade weekly nifty options at Arham Shares?",
    answer: (
      <>
        Trading options at Arham Share is straightforward. Simply open a demat
        account with us and ensure that you activate all the necessary segments
        during the account setup. Once completed, you'll be ready to trade
        options with Arham Share.
      </>
    ),
  },
  {
    question: "What is Algo trading?",
    answer: (
      <>
        Algorithmic trading, or algo-trading, involves executing trades using
        pre-programmed automated strategies. These programs are designed with
        specific parameters such as buy points, sell points, and hold points.
        The primary advantage of algo-trading is that it saves time and assists
        those who cannot monitor charts constantly, allowing for quicker trades
        based on tick prices.
      </>
    ),
  },
  {
    question: "If it is a ban period, Can the F&O positions be rolled over?",
    answer: (
      <>
        During the ban period for a traded contract, SEBI regulations prohibit
        rolling over open F&O positions. Trading is halted for stocks under the
        ban, and traders are not allowed to open new positions in these stocks
        during this time.
      </>
    ),
  },
  {
    question: "How to get a holding report?",
    answer: (
      <>
        Go to Arham Share’s backoffice page and log in with your user details.
        In the header section, click on the "Reports" tab, then select
        "Holdings." Here, you can view information about your holdings. To
        download the report, click on the Excel or PDF icon in the top right
        corner of the box.
      </>
    ),
  },
  {
    question: "How is Arham Share different from other brokers?",
    answer: (
      <>
        Arham Share, based in Surat, Gujarat, has been a leading stock broker
        for over 15 years. Renowned for its exceptional client service, Arham
        Share is committed to delivering top-notch financial solutions. Place
        your trust in Arham Share and embark on your remarkable financial
        journey with us.
      </>
    ),
  },
  {
    question: "How long will it take to open an online demat account?",
    answer: (
      <>
        The online account opening process typically begins when you submit the
        required documents. This process takes approximately 10-15 minutes to
        complete. Once all documents are submitted, your account will be
        activated within 24 hours, provided the KYC verification team
        successfully approves them.
      </>
    ),
  },
  {
    question: "How can you Call and Trade from Arham Share??",
    answer: (
      <>
        Arham Share offers a call and trade facility to all its clients. To use
        this service, simply contact our dealing support number and verify your
        details. Once your information is confirmed, our dealing assistants will
        execute your trade on your behalf. Best of all, Arham Share provides
        this call and trade service free of charge to all clients.
      </>
    ),
  },
  {
    question: "What kind of trading platforms are offered by Arham Share?",
    answer: (
      <>
        You can place your trades with Arham Share using three convenient
        methods:
        <br />
        1. Trado App: Available on both Android and iOS devices for mobile
        trading.
        <br />
        2. Web Trader: For PC users, you can trade through our Web Trader
        platform.
        <br />
        3. Call and Trade: If you are unable to access either of the above, you
        can place your orders using our Call and Trade facility.
      </>
    ),
  },
  {
    question:
      "What are the charges for opening a demat account with Arham Share?",
    answer: (
      <>
        Opening a demat account with Arham Share involves minimal charges, which
        are tailored to your needs. We offer different pricing slabs to suit
        various types of traders: long-term investors, beginner traders, and
        experienced professionals. You can select the plan that best fits your
        trading style and requirements.
      </>
    ),
  },
  {
    question: "How to open an offline demat account?",
    answer: (
      <>
        If you are an Indian resident and your phone number is linked to your
        Aadhaar card, you can complete the account opening process online. If
        your mobile number is not linked to your Aadhaar card, you'll need to
        follow the offline account opening process. For this, you will need to
        provide copies of all required documents and contact Arham Share’s
        customer service for assistance with opening a physical demat account.
      </>
    ),
  },
  {
    question: "How to contact Arham Share’s customer service representative?",
    answer: (
      <>
        You can reach out to Arham Share’s customer service representatives
        during the following hours:
        <br />
        - Trading Days: 9:00 AM to 6:00 PM
        <br />
        - Saturdays: 10:30 AM to 3:30 PM
        <br />
        Contact us at 0261-6794000. If the line is busy, you can also email us
        at contact@arhamshare.com, and our team will get back to you as quickly
        as possible.
      </>
    ),
  },
  {
    question:
      "What is an E-sign and why is it compulsory to complete the E-sign process while completing any process?",
    answer: (
      <>
        E-sign, or digital signature, is a mandatory step for completing the
        online submission of documents. This process involves digitally signing
        your documents using your Aadhaar card. To e-sign, keep your Aadhaar
        card handy, enter the 12-digit Aadhaar number on the NSDL E-sign page,
        and click submit. An OTP will be sent to the registered mobile number
        associated with your Aadhaar card. Enter the OTP to verify, and your
        documents will be digitally signed.
      </>
    ),
  },
  {
    question: "Can I link my OD bank account with Arham Share’s demat account?",
    answer: (
      <>
        No, you cannot link an Overdraft bank account with Arham Share. Only
        Savings accounts, Current accounts, and Cash Credit accounts can be
        linked for both individual and corporate account holders.
      </>
    ),
  },
  {
    question:
      "Can a current bank account be linked with Arham Share’s demat account?",
    answer: (
      <>
        Yes, you can link a Current bank account with Arham Share’s demat
        account as either the default or a secondary bank account. Ensure that
        the name on the bank account matches the name on the PAN submitted
        during the demat account opening.
      </>
    ),
  },
  {
    question: "How to add a nominee at Arham Share?",
    answer: (
      <>
        To add a nominee at Arham Share, follow these steps:
        <br />
        1. Log in to your Arham Share Backoffice with your user details.
        <br />
        2. Navigate to the "My Account" section.
        <br />
        3. Select the "Add Nomination" option.
        <br />
        4. Fill in the required details and submit.
        <br />
        5. Complete the E-sign process to finalize the nomination.
      </>
    ),
  },
  {
    question: "Can I create an equity SIP order for any stock?",
    answer: (
      <>
        Yes, you can set up a Stock SIP (Systematic Investment Plan) for any
        equity stock using our Trado App. Here’s how:
        <br />
        1. Open the Trado App and select the stock you want to invest in.
        <br />
        2. Go to the "SIP Order" section.
        <br />
        3. Enter the quantity or amount, and set the cap price.
        <br />
        4. Choose the frequency of your investments.
        <br />
        5. Specify the total number of investments.
        <br />
        6. Place your order and ensure that you have sufficient funds in your
        trading account.
      </>
    ),
  },
  {
    question:
      "Can IPO bids be placed only through the UPI id linked to the primary bank account of Arham Share?",
    answer: (
      <>
        No, it is not necessary to place your IPO bid using the UPI ID linked to
        your primary bank account at Arham Share. You can use a different bank
        account to place your IPO bid. However, it is preferable to use the UPI
        ID linked to a bank account registered with Arham Share to minimize the
        risk of failures or rejections.
      </>
    ),
  },
  {
    question: "When will funds be unblocked if the IPO is not allotted?",
    answer: (
      <>
        Typically, it takes 2-3 working days to unblock your funds if your IPO
        bid is not allotted. The exact time may vary depending on the depository
        participant, but in most cases, the funds are unblocked within a week.
      </>
    ),
  },
  {
    question:
      "Can I apply for multiple applications in the same IPO using the same UPI id?",
    answer: (
      <>
        You can submit only one application for the same IPO using the same UPI.
        Multiple applications from the same UPI may result in the rejection of
        your IPO bid. However, you can adjust the quantity of lots within a
        single application if you wish to apply for more than one lot.
      </>
    ),
  },
];

function Faqs() {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const halfItemsPerPage = itemsPerPage / 2;
  const half = Math.ceil(faqData.length / 2);

  // Calculate the data for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = faqData.slice(indexOfFirstItem, indexOfLastItem);

  // Split current data into two columns
  const firstColumn = currentData.slice(0, halfItemsPerPage);
  const secondColumn = currentData.slice(halfItemsPerPage, itemsPerPage);

  // Calculate total pages
  const totalPages = Math.ceil(faqData.length / itemsPerPage);

  // Handle page change
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: "20px" }}>
      <Typography
        sx={{
          fontSize: "30px",
          fontFamily: "Ubuntu",
          fontWeight: 500,
          textAlign: "center",
          mb: 4,
        }}
      >
        Frequently Asked Questions
      </Typography>

      <Grid container spacing={4}>
        {/* First column */}
        <Grid item xs={12} md={6}>
        {firstColumn.map((faq, index) => (
            <Box key={index} mb={2}>
              <Accordion
                sx={{
                  backgroundColor: "#ECF4F3",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  boxShadow: "none",
                  borderRadius: "20px",
                }}
              >
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  sx={{
                    minHeight: "64px",
                    "& .MuiAccordionSummary-content": {
                      margin: 0,
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Ubuntu",
                      fontWeight: 500,
                      color: "#396A62",
                      textAlign: "left",
                    }}
                  >
                    {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Ubuntu",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {faq.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          ))}
        </Grid>
        {/* Second column */}
        <Grid item xs={12} md={6}>
          {secondColumn.map((faq, index) => (
            <Box key={index} mb={2}>
              <Accordion
                sx={{
                  backgroundColor: "#ECF4F3",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  boxShadow: "none",
                  borderRadius: "20px",
                }}
              >
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls={`panel${half + index}-content`}
                  id={`panel${half + index}-header`}
                  sx={{
                    minHeight: "64px",
                    "& .MuiAccordionSummary-content": {
                      margin: 0,
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Ubuntu",
                      fontWeight: 500,
                      color: "#396A62",
                      textAlign: "left",
                    }}
                  >
                    {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Ubuntu",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {faq.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          ))}
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
         <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleChange}
          color="primary"
          shape="rounded"
        />
      </Box>
    </Box>
  );
}

export default Faqs;
