import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const AnimatedGrid = ({ children, style }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: inView ? 1 : 0 }}
      transition={{ duration: 1 }}
      style={style}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedGrid;
