import React, { useState, useEffect } from "react";
import "../../style/style.css";
import qrCode from "../../assets/images/ArhamImg/qrCode.png";
import QR from "../../assets/images/ArhamImg/QR.png";
import { Grid, Typography } from "@mui/material";
import playstore from "../../assets/images/logo/playstore.png";
import applelogo from "../../assets/images/logo/applelogo.png";

function QRCodeDisplay() {
  const androidAppUrl =
    "https://bit.ly/000AND";
  const iosAppUrl = "https://bit.ly/000IOS";

  const [showScanBar, setShowScanBar] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 100;
      if (window.scrollY > scrollThreshold) {
        setShowScanBar(true);
      } else {
        setShowScanBar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className={`scan-bar ${showScanBar ? "visible" : ""}`}>
      <div
        className={`qr-icon ${hovered ? "hidden" : ""}`}
        onMouseEnter={handleMouseEnter}
      >
        <div className="qr-icon-image">
          <img src={QR} alt="QR Code" className="qr-icon-image" />
        </div>
      </div>
      {hovered && (
        <div className="qr-card" onMouseLeave={handleMouseLeave}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Ubuntu",
                }}
              >
                Download Arham Share Mobile App
              </Typography>
            </Grid>
          </Grid>

          <img src={qrCode} alt="QR Code" className="qr-image" />
          <Typography
            className="qr-links"
            sx={{
              fontSize: "15px",
              fontWeight: "bold",
              fontFamily: "Ubuntu",
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <a href={androidAppUrl} target="_blank" rel="noopener noreferrer">
              <img src={playstore} style={{ width: "20px", height: "20px" }} />
            </a>
            <a href={iosAppUrl} target="_blank" rel="noopener noreferrer">
              <img src={applelogo} style={{ width: "20px", height: "20px" }} />
            </a>
          </Typography>
        </div>
      )}
    </div>
  );
}

export default QRCodeDisplay;
