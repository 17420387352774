import { Grid, Typography, Box } from "@mui/material";
import React from "react";
import Stock from "../assets/images/Product-Services/stock.png";
import Booking from "../assets/images/Product-Services/booking.png";
import Commoditie from "../assets/images/Product-Services/commodities.png";
import Currency from "../assets/images/Product-Services/currency.png";
import Slbm from "../assets/images/Product-Services/slbm.png";
import Algo from "../assets/images/Product-Services/algo.png";
import Mutual from "../assets/images/Product-Services/mutual.png";
import Future from "../assets/images/Product-Services/future.png";

function Services() {
  return (
    <Box sx={{ px: 2 }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={12}>
          <Typography
            sx={{
              fontSize: "38px",
              fontFamily: "Ubuntu",
              fontWeight: 500,
              pt: 6,
              textAlign: "center",
            }}
          >
            Our <span style={{ color: "#396A62" }}>Services</span>
          </Typography>
        </Grid>

        <Grid container spacing={4} sx={{ mt: 2 }}>
          {[
            {
              image: Stock,
              title: "Equity Stocks",
              description: (
                <>
                  Arham Share makes it simple to invest in stocks, whether your
                  goal is to purchase and sell equities within the same day for
                  short-term profits or to retain them for long-term benefits
                  via delivery. With Arham Share's assistance, you may carry it
                  out without any problem.
                </>
              ),
            },
            {
              image: Future,
              title: "Futures & Options",
              description:
                "In F&O trading, you become a stock market forecaster. Contracts allow you to forecast future price fluctuations in order to increase possible earnings (or losses). Options give you the right to purchase or sell at a certain price by a specific date, whereas futures lock in a future buy/sell price. So, before joining this fast-paced sector, much study is required. For expert advice on F&O trading, consult Arham Share Research Portal.",
            },

            {
              image: Commoditie,
              title: "Commodities",
              description:
                "Commodities trading positions you as a market expert for materials like crude oil, gold, silver, and natural gas. By buying and selling futures contracts, you can anticipate price movements, diversify your portfolio, and hedge against inflation. However, given the unpredictable nature of global dynamics, a deep understanding of each commodity's specific market is crucial for informed trading decisions.",
            },
            {
              image: Currency,
              title: "Currency",
              description: (
                <>
                  Currency trading is not the same as buying companies; it is
                  the trading of currencies. You speculate on currency rate
                  changes, hoping to profit from variations. What's the upside?
                  <br />A market that is open 24/7 and has a high return
                  potential. It's a rollercoaster ride that has great volatility
                  and requires a thorough grasp of economics.
                </>
              ),
            },
            {
              image: Slbm,
              title: "SLBM",
              description:
                "In Stock Lending and Borrowing (SLB), you serve as a market matchmaker for idle shares. Instead of purchasing or selling directly, you can make passive income by lending out your existing shares or borrowing shares to execute short selling methods. This unseen trade keeps the market moving and encourages a variety of investing options.",
            },
            {
              image: Algo,
              title: "API",
              description:
                "Want to improve your trading using technology? The Arham Share API allows you to connect to advanced trading tools. Consider a tireless assistant that continually monitors markets and executes trades based on your instructions! Trading with Arham Share's API can automate your approach for faster outcomes while freeing up your time for other tasks.",
            },
            {
              image: Mutual,
              title: "Mutual Fund",
              description:
                "Mutual funds are like investment sprinkles! You put your money in alongside others, and a fund manager distributes it among a variety of investments such as stocks and bonds. This diversity smoothes the ride and may increase your profits. With so many funds to choose from, select the one that best matches your risk tolerance for a brighter financial future! Adding and updating the top 10 performing mutual funds or types of mutual funds every month whenever the fact sheet or the data is derived.",
            },

            {
              image: Booking,
              title: "Personalized Service / Discount Service",
              description: (
                <>
                  Arham Share stands out with its personalized stock broking
                  services, designed to cater to your specific investment
                  requirements. Trade Circle, on the other hand, provides a
                  standardized brokerage approach. Both options are available,
                  so you can choose the one that best aligns with your goals.
                </>
              ),
            },
          ].map((service, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <Box
                sx={{
                  border: "1px solid #e0e0e0",
                  borderRadius: "30px",
                  padding: "10px",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  pl: 2,
                  // justifyContent: "center",
                }}
              >
                <img
                  src={service.image}
                  alt={service.title}
                  style={{
                    display: "block",
                    width: "100px",
                    marginBottom: "10px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: {md:"20px" ,xs:"22px"},
                    fontFamily: "Ubuntu",
                    fontWeight: 500,
                    pb: 2,
                    textAlign: "left",
                  }}
                >
                  {service.title}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Ubuntu",
                    color: "#3D3D3D",
                    textAlign: "left",
                  }}
                >
                  {service.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Services;
