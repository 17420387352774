import React, { useEffect } from "react";
import Vector from "../assets/images/Partner/vector.png";
import Auth from "../assets/images/Partner/auth.png";
import Cities from "../assets/images/Partner/cities.png";
import Online from "../assets/images/Partner/online.png";
import Deal from "../assets/images/Partner/deal.png";
import Frame from "../assets/images/Partner/Frame.png";
import { Grid, Box, Typography } from "@mui/material";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";

function InvestmentJourney() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        y: 0,
        x: 0,
        transition: { duration: 0.5 },
      });
    }
  }, [controls, inView]);

  return (
    <>
      <Grid
        container
        sx={{
          px: { xs: 4, md: 4, lg: 14 },
          pt: 8,
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box
              // component={motion.div}
              // initial={{ opacity: 0, x: -90 }}
              // animate={controls}
              sx={{
                display: { md: "flex" },
                justifyContent: { md: "flex-start" },
              }}
            >
              <img src={Vector} alt="Vector" style={{ width: "80%" }} />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontSize: { md: "29px", xs: "24px" },
                    fontWeight: 500,
                    textAlign: "left",
                    fontFamily: "Ubuntu",
                    pt:  4 ,
                  }}
                >
                  Be Your Own Boss, Start Your Side Income with{" "}
                  <span style={{ color: "#396A62" }}>Arham Share</span>
                </Typography>
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontSize: "13px",
                    textAlign: "left",
                    fontFamily: "Ubuntu",
                  }}
                >
                  Building your own future is within grasp. Arham Share provides
                  a unique chance to become a partner and learn about investing,
                  even if you already have a full-time work or a company.
                </Typography>
              </Grid>

              <Grid item xs={12} md={12}>
                <Grid container spacing={2} ref={ref}>
                  <Grid item xs={12} md={4}>
                    <img src={Auth} style={{ width: "70%" }} />
                    <Typography
                      sx={{
                        fontSize: { md: "40px", xs: "25px" },
                        fontWeight: 600,
                        color: "#396A62",
                        fontFamily: "Ubuntu",
                      }}
                    >
                      <CountUp start={0} end={500} duration={2} />+
                    </Typography>
                    Authorised partners
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <img src={Cities} style={{ width: "70%" }} />
                    <Typography
                      sx={{
                        fontSize: { md: "40px", xs: "25px" },
                        fontWeight: 600,
                        color: "#396A62",
                        fontFamily: "Ubuntu",
                      }}
                    >
                      <CountUp start={0} end={450} duration={2} />+
                    </Typography>
                    Cities across india
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <img src={Online} style={{ width: "70%" }} />
                    <Typography
                      sx={{
                        fontSize: { md: "40px", xs: "25px" },
                        fontWeight: 600,
                        color: "#396A62",
                        fontFamily: "Ubuntu",
                      }}
                    >
                      <CountUp start={0} end={30} duration={2} />
                      K+
                    </Typography>
                    Clients
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container pt={6}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontSize: { md: "29px", xs: "25px" },
                    fontWeight: 500,
                    textAlign: "left",
                    fontFamily: "Ubuntu",
                    pt: { md: 4 },
                  }}
                >
                  Flexible Partnership,{" "}
                  <span style={{ color: "#396A62" }}> Rewarding Results</span>
                </Typography>
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    textAlign: "left",
                    fontFamily: "Ubuntu",
                  }}
                >
                  Building your own future is within grasp. Arham Share provides
                  a unique chance to become a partner and make your own
                  business, it doesn't matter even if you already have a
                  full-time job.
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    fontSize: "18px",
                    textAlign: "left",
                    fontFamily: "Ubuntu",
                    fontWeight: 500,
                  }}
                >
                  At Arham Share you will get the best deals around the market,
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box sx={{ pt: 0 }}>
                  {[
                    "Best brokerage sharing in market",
                    "Personalized services according to your need",
                    "Dedicated RM support",
                    "Marketing support",
                    "Timely payouts",
                    "Incentives offers and surprise events",
                  ].map((text, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        gap: "16px",
                        mb: 1,
                      }}
                    >
                      <img src={Frame} alt="Frame" />
                      <Typography
                        sx={{
                          fontFamily: "Ubuntu",
                          fontWeight: 400,
                          textAlign: "left",
                        }}
                      >
                        {text}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box
              // component={motion.div}
              // initial={{ opacity: 0, x: 100 }}
              // animate={controls}
              sx={{
                display: { md: "flex" },
                justifyContent: { md: "flex-end" },
              }}
            >
              <img src={Deal} alt="Deal" style={{ width: "82%" }} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default InvestmentJourney;
