import React, { useState, useEffect } from "react";
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Typography,
  Box,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import { useNavigate } from "react-router";
import Header from "../Component/HomePage/Header";
import Fotter from "../Component/Footer";
import DownloadIcon from "../assets/images/IPOs/Download.png";

function Technical() {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState("ODIN");
  const [loading, setLoading] = useState(false); // Loading state
  const [progress, setProgress] = useState(0); // Progress state

  const handleBreadcrumbClick = (event, path) => {
    event.preventDefault();
    navigate(path);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handleDownloadClick = async (fileIdentifier) => {
    setLoading(true); // Show the loader
    setProgress(0); // Reset progress

    try {
      const downloadUrl = `${process.env.REACT_APP_API_BASE_URL}/arham_web/v1/fetch-document/?section=technical&sub_section=${selectedCategory}&file_identifier=${fileIdentifier}`;
      const response = await fetch(downloadUrl, { method: "GET" });

      if (!response.ok) {
        throw new Error(`Download failed with status ${response.status}`);
      }

      const blob = await response.blob();
      const contentType = response.headers.get("content-type");

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      const extension = getExtension(contentType);
      link.download = `${fileIdentifier}.${extension}`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Simulate progress update
      for (let i = 1; i <= 100; i++) {
        setTimeout(() => setProgress(i), i * 10); // Simulated progress increment
      }
    } catch (error) {
      console.error("Download error:", error);
    } finally {
      setTimeout(() => {
        setLoading(false); // Hide the loader
        setProgress(0); // Reset progress
      }, 1000); // Give time for the progress to show
    }
  };

  const getExtension = (contentType) => {
    switch (contentType) {
      case "application/pdf":
        return "pdf";
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "docx";
      case "application/msword":
        return "doc";
      default:
        return "";
    }
  };

  const dataMap = {
    ODIN: [
      { title: "Odin-1 setup 32bit", file_identifier: "Odin-1 setup 32bit" },
      { title: "Odin-1 setup 64bit", file_identifier: "Odin-1 setup 64bit" },
      { title: "odin-4 setup 32bit", file_identifier: "odin-4 setup 32bit" },
      { title: "odin-4 setup 64bit", file_identifier: "odin-4 setup 64bit" },
      {
        title: "Odin-Diet setup 32bit",
        file_identifier: "Odin-Diet setup 32bit",
      },
      {
        title: "Odin-Diet setup 64bit",
        file_identifier: "Odin-Diet setup 64bit",
      },
      {
        title: "FortiClientOnlineInstaller (1)",
        file_identifier: "FortiClientOnlineInstaller (1)",
      },
    ],
    XTS: [{ title: "ARHAM_XTS_64BIT", file_identifier: "ARHAM_XTS_64BIT" }],
    Greek: [
      {
        title: "GETSClient_5.0.191022_64bit_050724",
        file_identifier: "GETSClient_5.0.191022_64bit_050724",
      },
    ],
    Trado: [],
    Support: [
      {
        title:
          "RealVNC Enterprise Edition 4.6.1 (32+64bit) + SERIAL KEYS - Copy",
        file_identifier: "RealVNC Enterprise Edition",
      },
      { title: "vredist", file_identifier: "vredist" },
    ],
  };

  return (
    <>
      <Header />

      <Grid
        container
        sx={{
          background:
            "linear-gradient(180deg, #ffffff 0%, #c1d8d4 0%, #ffffff 40%)",
          px: { xs: 4, md: 20 },
        }}
      >
        <Grid container pt={6}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ margin: "auto 0", textAlign: "center" }}
          >
            <Typography
              sx={{
                fontSize: { md: "45px", xs: "32px" },
                fontFamily: "Ubuntu",
                fontWeight: 500,
              }}
            >
              Technical Downloads
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} sx={{ pt: 6 }}>
          <Typography
            sx={{
              fontSize: "35px",
              fontFamily: "Ubuntu",
              fontWeight: 500,
              textAlign: "left",
              color: "#396A62",
            }}
          ></Typography>
        </Grid>
        <Grid container spacing={2}>
          {["ODIN", "XTS", "Greek", "Trado", "Support"].map((category) => (
            <Grid item key={category}>
              <Button
                variant="contained"
                onClick={() => handleCategoryClick(category)}
                sx={{
                  backgroundColor:
                    selectedCategory === category ? "#396A62" : "#ffffff",
                  color: selectedCategory === category ? "#ffffff" : "#396A62",
                  border: "1px solid #396A62",
                  fontFamily: "Ubuntu",
                  fontWeight: 500,
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#396A62",
                    color: "#ffffff",
                  },
                }}
              >
                {category}
              </Button>
            </Grid>
          ))}
        </Grid>

        {/* Download Cards */}
        {selectedCategory === "Trado" ? (
          <Grid container spacing={2} sx={{ pt: 8 }}>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  textAlign: "center",
                  border: "1px solid #E0E0E0",
                  borderRadius: "8px",
                  padding: 2,
                  height: "100%",
                  my: 1,
                  "&:hover": {
                    backgroundColor: "#E0E0E0",
                  },
                }}
              >
                <Box sx={{ pt: 2 }}>
                  <img
                    src={DownloadIcon}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open("https://bit.ly/000AND", "_blank")
                    }
                  />
                </Box>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: { xs: "14px", md: "16px" },
                    color: "#3D3D3D",
                    mb: 2,
                  }}
                >
                  Android App Download
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  textAlign: "center",
                  border: "1px solid #E0E0E0",
                  borderRadius: "8px",
                  padding: 2,
                  height: "100%",
                  my: 1,
                  "&:hover": {
                    backgroundColor: "#E0E0E0",
                  },
                }}
              >
                <Box sx={{ pt: 2 }}>
                  <img
                    src={DownloadIcon}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open("https://bit.ly/000IOS", "_blank")
                    }
                  />
                </Box>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: { xs: "14px", md: "16px" },
                    color: "#3D3D3D",
                    mb: 2,
                  }}
                >
                  iOS App Download
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} sx={{ pt: 8 }}>
            {dataMap[selectedCategory].map((file, index) => (
              <Grid item xs={12} sm={2} md={3} key={index}>
                <Box
                  sx={{
                    textAlign: "center",
                    border: "1px solid #E0E0E0",
                    borderRadius: "8px",
                    height: "100%",
                    my: 1,
                    "&:hover": {
                      backgroundColor: "#E0E0E0",
                    },
                  }}
                >
                  <Box sx={{ pt: 2 }}>
                    <img
                      src={DownloadIcon}
                      alt={`${file.file_name} Download`}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDownloadClick(file.file_identifier)}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: { xs: "12px", md: "14px" },
                      color: "#3D3D3D",
                      mt: 2,
                      px: 2,
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                    }}
                  >
                    {file.title}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}

        {/* Loader Overlay */}
        {loading && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent background
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 9999,
            }}
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 100,
                height: 100,
              }}
            >
              <CircularProgress
                color="inherit"
                sx={{
                  position: "absolute",
                  color: "#B0B0B0",
                }}
                value={progress}
                size={60}
                thickness={4}
              />
              <Typography
                variant="body1"
                sx={{
                  position: "absolute",
                  fontSize: "18px",
                  fontWeight: 500,
                }}
              >
                {progress}%
              </Typography>
            </Box>
          </Box>
        )}
      </Grid>

      <Fotter />
    </>
  );
}

export default Technical;
