import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Checkbox,
  Typography,
  Tooltip,
  keyframes,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const SignIn = ({ onModeChange }) => {
  const formik = useFormik({
    initialValues: {
      mobileNumber: "",
      email: "",
    },
    validationSchema: Yup.object({
      mobileNumber: Yup.string()
        .length(10, "Mobile number must be 10 digits")
        .required("Mobile number is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const handleOpenDematClick = () => {
    window.location.href = "https://ekyc.arhamshare.com/";
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      {/* <Grid item xs={8} md={6} sx={{ pt: 2, display: "flex" }} lg={9}>
        <TextField
          name="mobileNumber"
          label="Enter Mobile Number"
          inputProps={{ maxLength: 10 }}
          variant="outlined"
          fullWidth
          // size="small"
          id="outlined-size-small"
          value={formik.values.mobileNumber}
          onChange={formik.handleChange}
          error={
            formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)
          }
          helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
          InputLabelProps={{
            style: {
              fontFamily: "Ubuntu",
            },
          }}
          InputProps={{
            sx: {
              height: "50px",
              borderRadius: "13px",
              fontFamily: "Ubuntu",
            },
          }}
          sx={{
            width: "450px",
          }}
        />
      </Grid>
      {formik.values.mobileNumber.length === 10 && (
        <>
          <Grid item xs={12} md={6} sx={{ pt: 2, display: "flex" }} lg={9}>
            <TextField
              name="email"
              label="Enter Email"
              variant="outlined"
              fullWidth
              // size="small"
              id="outlined-size-small"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              InputLabelProps={{
                style: {
                  fontFamily: "Ubuntu",
                },
              }}
              InputProps={{
                sx: {
                  height: "50px", // Set your desired height
                  borderRadius: "13px",
                  fontFamily: "Ubuntu",
                },
              }}
              sx={{
                width: "450px",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sm={12}
            lg={6}
            sx={{ pt: 2, display: "flex" }}
          >
            <Tooltip title="Send OTP">
              <Button
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "#396A62",
                  "&:hover": {
                    backgroundColor: "#0B2A29",
                  },
                  textTransform: "none",
                  fontFamily: "Ubuntu",
                  border: "1px #0B2A29",
                  borderRadius: "10px",
                  fontSize: "large",
                  width: "60%",
                  height: "50px",
                }}
                type="button"
                onClick={() => console.log("Send OTP clicked")}
              >
                <span style={{ fontSize: "15px" }}>Send OTP</span>
              </Button>
            </Tooltip>
          </Grid>
        </>
      )}
      <Grid item xs={12} md={10} lg={12} sx={{ display: "flex" }}>
        <Grid sx={{ pt: 2 }}>
          <Checkbox
            sx={{
              width: "20px",
              height: "14px",
              color: "lightgray",
              "& .MuiSvgIcon-root": {
                fontSize: "16px",
              },
            }}
            checked={true}
          />
        </Grid>
        <Typography
          sx={{
            fontSize: "10px",
            pt: 2,
            textAlign: "left",
            fontFamily: "Ubuntu",
            color: "#939393",
            ml: "-2px",
          }}
        >
          I/We hereby consent to open my account with Arham through ekyc/Online
          and I understand that I'll be contacted by Arham on my/our mobile
          number and email-id in respect to my application and other products.
          <span style={{ color: "#396A62" }}>terms & conditions</span>
        </Typography>
      </Grid> */}
      {/* {formik.values.mobileNumber.length !== 10 && ( */}
        <Grid
          item
          xs={12}
          md={4}
          sm={12}
          lg={6}
          sx={{ pt: 2, display: "flex" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenDematClick}
            endIcon={<ArrowForwardIcon sx={{ fontSize: "30px" }} />}
            sx={{
              backgroundColor: "#396A62",
              "&:hover": {
                backgroundColor: "#0B2A29",
              },
              fontFamily: "Ubuntu",
              border: "1px #0B2A29",
              borderRadius: "10px",
              // width: "55%",
              // height: "50px",
              textTransform: "none",
            }}
            className="header-btn"
            type="submit"
          >
            <span style={{ fontSize: "18px" }}>Open Demat Account</span>
          </Button>
        </Grid>
      {/* )} */}
      <Grid item xs={12} md={12} lg={12} sx={{ pt: 1 }}>
        <Typography
          align="left"
          color="#939393"
          style={{ fontWeight: "400", fontFamily: "Ubuntu" }}
        >
          {/* <span style={{ fontWeight: "500" }}>Don't have an account?</span>
          <span
            style={{
              color: "#396A62",
              cursor: "pointer",
              fontWeight: "bold",
              paddingLeft: "5px ",
            }}
            onClick={onModeChange}
          >
            Sign Up
          </span> */}
        </Typography>
      </Grid>
    </form>
  );
};

export default SignIn;
