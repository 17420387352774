import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  TextField,
  Box,
  styled,
  Rating,
} from "@mui/material";
import axios from "axios";

function GetTouchForm() {
  const [selectedForm, setSelectedForm] = useState("suggestion");
  const [ratingValue, setRatingValue] = useState(0);
  const [captcha, setCaptcha] = useState("");
  const [captchaInput, setCaptchaInput] = useState("");
  const [captchaError, setCaptchaError] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/arham_web/v1/generate-captcha`)
      .then((response) => {
        if (response.data.status === "Success") {
          setCaptcha(response.data.data.capcha);
        }
      })
      .catch((error) => {
        console.error("Error fetching captcha:", error);
      });
  }, []);

  const handleButtonClick = (form) => {
    setSelectedForm(form);
  };

  const CustomTextField = styled(TextField)(() => ({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "5px",
        width: "150px",
      },
      "&:hover fieldset": {
        borderRadius: "5px",
      },
      "&.Mui-focused fieldset": {
        borderRadius: "5px",
      },
    },
  }));

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate captcha
    if (captchaInput !== captcha) {
      setCaptchaError("Captcha does not match.");
      return;
    }

    setCaptchaError("");

    // Collect form data
    const formData = new FormData(event.target);
    const formObject = Object.fromEntries(formData.entries());

    // Include the form type in the form data
    const dataToSubmit = {
      ...formObject,
      form_type: selectedForm, // Add form_type based on the selected form
      rating: selectedForm === "ratings" ? ratingValue : 0, // Add rating if "Ratings" is selected
    };

    console.log("Form Data Submitted:", dataToSubmit);

    // Send form data to the server
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/arham_web/v1/feedback/`, dataToSubmit)
      .then((response) => {
        if (response.status === 201) {
          // Handle success (e.g., show a success message)
          console.log("Form submitted successfully");
        }
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 6,
      }}
    >
      {/* Buttons */}
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Button
            onClick={() => handleButtonClick("suggestion")}
            sx={{
              backgroundColor:
                selectedForm === "suggestion" ? "#396A62" : "transparent",
              color: selectedForm === "suggestion" ? "white" : "#396A62",
              borderRadius: "5px",
              border: "1px solid #396A62",
              textTransform: "none",
              padding: "8px 20px",
            }}
          >
            Suggestion
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => handleButtonClick("ratings")}
            sx={{
              backgroundColor:
                selectedForm === "ratings" ? "#396A62" : "transparent",
              color: selectedForm === "ratings" ? "white" : "#396A62",
              borderRadius: "5px",
              textTransform: "none",
              border: "1px solid #396A62",
              padding: "8px 20px",
            }}
          >
            Ratings
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => handleButtonClick("complaint")}
            sx={{
              backgroundColor:
                selectedForm === "complaint" ? "#396A62" : "transparent",
              color: selectedForm === "complaint" ? "white" : "#396A62",
              borderRadius: "5px",
              border: "1px solid #396A62",
              textTransform: "none",
              padding: "8px 20px",
            }}
          >
            Complaint
          </Button>
        </Grid>
      </Grid>

      {/* Form */}
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          backgroundColor: "#EFF6F5",
          padding: "40px",
          borderRadius: "20px",
          width: { xs: "90%", md: "60%" },
          mt: 4,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textAlign: "center",
            mb: 4,
            fontFamily: "Ubuntu",
            fontWeight: 500,
          }}
        >
          Get in touch
        </Typography>

        <Grid container spacing={3}>
          {/* Name Field */}
          <Grid item xs={12} md={6}>
            <Typography sx={{ textAlign: "left", fontFamily: "Ubuntu", pb: 1 }}>
              Name
            </Typography>
            <TextField
              fullWidth
              placeholder="Name"
              variant="outlined"
              size="small"
              name="name"
            />
          </Grid>
          {/* Email Field */}
          <Grid item xs={12} md={6}>
            <Typography sx={{ textAlign: "left", fontFamily: "Ubuntu", pb: 1 }}>
              Email
            </Typography>
            <TextField
              fullWidth
              placeholder="Email"
              variant="outlined"
              size="small"
              name="email"
            />
          </Grid>

          {selectedForm === "ratings" ? (
            <Grid item xs={12} sx={{ display: "grid" }}>
              <Typography
                sx={{ textAlign: "left", fontFamily: "Ubuntu", pb: 1 }}
              >
                Your Rating
              </Typography>
              <Rating
                name="rating"
                value={ratingValue}
                onChange={(event, newValue) => setRatingValue(newValue)}
                precision={1}
                size="large"
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography
                sx={{ textAlign: "left", fontFamily: "Ubuntu", pb: 1 }}
              >
                Description
              </Typography>
              <TextField
                fullWidth
                placeholder="Description"
                multiline
                rows={4}
                variant="outlined"
                name="description"
              />
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <Typography sx={{ textAlign: "left", fontFamily: "Ubuntu", pb: 1 }}>
              Captcha
            </Typography>
            <CustomTextField
              fullWidth
              value={captcha}
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={{ textAlign: "left", fontFamily: "Ubuntu", pb: 1 }}>
              Enter Captcha
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter Captcha"
              variant="outlined"
              size="small"
              value={captchaInput}
              onChange={(e) => setCaptchaInput(e.target.value)}
              error={!!captchaError}
              helperText={captchaError}
            />
          </Grid>

          <Grid item xs={6}></Grid>
          <Grid item xs={6} sx={{ display: "flex" }}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#396A62",
                "&:hover": {
                  backgroundColor: "#396A62",
                },
                color: "white",
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default GetTouchForm;
