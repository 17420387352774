import React, { useState } from "react";
import { Box, Button, Card, Grid, Typography } from "@mui/material";

const locations = [
  {
    name: "Surat",
    address:
      "601-606, Central Business Hub, Parle Point, Surat, Gujarat - 395007",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.9653749610617!2d72.79210811481227!3d21.17294348493756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04e77566930db%3A0x1abe4e7a2b647043!2sCentral%20Business%20Hub%2C%20601-606%2C%20Parle%20Point%2C%20Umra%20Gam%2C%20Athwa%2C%20Surat%2C%20Gujarat%20395007!5e0!3m2!1sen!2sin!4v1626183587404!5m2!1sen!2sin",
  },
  {
    name: "Gandhinagar",
    address:
      "A X-Change Plaza, 416-417, Dalal Street, near Hotel Grand Mercure, Gujarat International Finance Tec-City, Gandhinagar, Gujarat 382355",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3668.1920051814236!2d72.68163217226515!3d23.163191364843204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c2bb1904b7689%3A0x233b5760b495f6fc!2sArham%20Share%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1722414668259!5m2!1sen!2sin",
  },
  {
    name: "Ahmedabad",
    address:
      "903, BVR-Ek, Inder Residency, Hotel, Ellisbridge, Ahmedabad, Gujarat 380006",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1880085.4210909642!2d71.0309924!3d23.022236!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e85ac508bd293%3A0x55cc79166ef9ca37!2sArham%20Share%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1723787926568!5m2!1sen!2sin",
  },

  {
    name: "Mumbai",
    address:
      "Office-2, AML Centre, 1st floor,Mahakali Caves Road, ANDheri East,MIDC 8 Mahal Indl Estate, Mumbai",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7539.402207533993!2d72.8532734134295!3d19.12076410000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c82faf821935%3A0xe4ef549c36f39545!2sAML%20Centre-%201%2C%204%20th%20Floor%2C!5e0!3m2!1sen!2sin!4v1724416024280!5m2!1sen!2sin",
  },
  {
    name: "Delhi",
    address: "Ansal Tower, B-8, 38, Nehru Place, New Delhi, Delhi 110019",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.9653630190774!2d77.1967483!3d28.540760799999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3e1f580fd5b%3A0x6e740f2e90ab6308!2sArham%20Share%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1723788045712!5m2!1sen!2sin",
  },
  {
    name: "Hyderabad",
    address:
      "1st Floor, House No - 3, 43 - 165, above Gunshyam super market, Krishnapuri Colony, Marredpally, Secunderabad, Telangana 500026",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.096403063562!2d78.5040771!3d17.455099399999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9be709408681%3A0xf75ce2015d2f4c30!2sArham%20Share%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1723788223873!5m2!1sen!2sin",
  },
  {
    name: "Rajkot",
    address:
      "805, The Spire, BRTS bus stop, 150 Feet Ring Rd, near Sheetal Park, Padmi Society, Mavdi, Rajkot, Gujarat 360007",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14768.573204886814!2d70.7790107!3d22.2725609!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959cb15a8a3d9cb%3A0x439517baaf90cbcd!2sArham%20Share%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1722417196690!5m2!1sen!2sin",
  },
  {
    name: "Navsari",
    address:
      " Ashoka Tower, 5-Upper Laval, Railway Station Rd, opp. Super Studio, Sandhkuva Gate, `Navasari, Gujarat 396445",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14904.242967365604!2d72.9200149!3d20.9500757!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be0f77650b5e39f%3A0x6ea07a0fbe304e14!2sArham%20Share%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1722417104820!5m2!1sen!2sin",
  },
];

function MapComponent() {
  const [selectedLocation, setSelectedLocation] = useState(locations[0]);

  const handleLocationClick = (location) => {
    setSelectedLocation(location);
  };

  return (
    <Grid
      container
      spacing={2}
      mt={8}
      sx={{ backgroundColor: "#EDF0E1", borderRadius: "40px", px: 2 }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: { md: "28px", xs: "22px" },
            fontFamily: "Ubuntu",
            fontWeight: 500,
            textAlign: "left",
            pl: { xs: 2, md: 4 },
          }}
        >
          <span style={{ color: "#697523" }}>Our Presence </span>across India
        </Typography>
        <Typography
          sx={{
            fontSize: { md: "15px", xs: "13px" },
            fontFamily: "Ubuntu",
            color: "#3D3D3D",
            textAlign: "left",
            pt: 1,
            pl: { xs: 2, md: 4 },
          }}
        >
          Find your nearest office from the list below
        </Typography>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          //   spacing={2}
          sx={{
            mt: 2,
            justifyContent: { md: "flex-start", gap: 8 },
            pl: 4,
          }}
        >
          {locations.map((location, index) => (
            <Grid key={index}>
              <Button
                onClick={() => handleLocationClick(location)}
                sx={{
                  textTransform: "none",
                  height: "35px",
                  width: "110px",
                  fontFamily: "Ubuntu",
                  backgroundColor:
                    selectedLocation.name === location.name
                      ? "#697523"
                      : "transparent",
                  color:
                    selectedLocation.name === location.name
                      ? "#fff"
                      : "#697523",
                  border:
                    selectedLocation.name === location.name
                      ? "none"
                      : "1px solid #697523",
                  "&:hover": {
                    backgroundColor:
                      selectedLocation.name === location.name
                        ? "#697523"
                        : "transparent",
                    color:
                      selectedLocation.name === location.name
                        ? "#fff"
                        : "#697523",
                    borderColor:
                      selectedLocation.name === location.name
                        ? "#697523"
                        : "#697523",
                  },
                }}
              >
                {location.name}
              </Button>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ display: "flex", pt: "30px", pr: 4, pl: 2 }}>
          <iframe
            src={selectedLocation.mapUrl}
            width="100%"
            height="300"
            style={{ border: "4px solid white", borderRadius: "40px" }}
            allowFullScreen=""
            loading="lazy"
            title="Google Map"
          ></iframe>
        </Box>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ position: "relative", bottom: "10%", px: { xs: 4, md: 12 } }}
        >
          <Card sx={{ textAlign: "left", pl: 4, fontFamily: "Ubuntu", py: 2 }}>
            <Typography
              variant="h6"
              sx={{ fontFamily: "Ubuntu", color: "#697523" }}
            >
              {selectedLocation.name}
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontFamily: "Ubuntu", fontSize: "13px" }}
            >
              Arham Share Pvt Ltd <br />
              <span style={{ color: "#3D3D3D" }}>
                {selectedLocation.address}
              </span>
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MapComponent;
