import React from "react";
import male from "../assets/images/Icon/male.png";
import woman from "../assets/images/Icon/woman.png";
import Vector from "../assets/images/Vector.png";
import Star from "../assets/images/Star.png";
import rating from "../assets/images/logo/rating.png";
import Slider from "react-slick";
import { Box, Grid, Typography } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../style/style.css";

function Review() {

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const testimonials = [
    {
      name: "Kabir Sharma",
      text: "I have been trading with Arham Share from past 4 months. Their client service has been excellent. My friend suggested to me Arham Share, I switched and it was one of the best decision I ever made.",
      gender: "male",
    },
    {
      name: "Ankit Patel",
      text: "Stock market was new to me but with the help of trado app, I was able to understand trading easily. My friend suggested to me Arham Share, I switched and it was one of the best decision I ever made.",
      gender: "male",
    },
    {
      name: "Sejal Jain",
      text: "I have been trading in Arham Share’s application from past three months, the customer service is pretty positive and excellent environment at the office.",
      gender: "female",
    },
    {
      name: "Neha Dhanuka",
      text: "I would suggest everyone to open their demat account in Arham Share, cause this is one of the best place to start your investment journey.",
      gender: "female",
    },
    {
      name: "Karan Jiyaviya",
      text: "Great stock trading company in surat gujarat most trusted and valuable company. And friendly sported staff.",
      gender: "male",
    },
    {
      name: "Foram Dadawala",
      text: "Account opening process at Arham was easy and after that their service was cherry on a pie.",
      gender: "female",
    },
    {
      name: "Lokesh Panpaliya",
      text: "Arham share provides exceptional stock brokerage services with knowledgeable staff, excellent customer service, and a user-friendly platform, Transparency and Trust.I always feel confident that my investments are in safe hands..",
      gender: "male",
    },
    {
      name: "Divya kambalan",
      text: "Best application for trading and investment. The back office support was excellent . I got every report I wanted in instance. Excellent application overall.",
      gender: "female",
    },
    {
      name: "Hiral Gandhi",
      text: "Arhamshare offers its clients a wide range of different segments where we can invest our money! Great application to use .",
      gender: "female",
    },
    {
      name: "kalpana Bhatt",
      text: "I opened an account over here and the opening process went very smoothly without any problem.",
      gender: "male",
    },
  ];
  
  return (
    <>
      <Grid
        item
        sm={12}
        md={12}
        xs={12}
        sx={{
          backgroundColor: "#EDF0E1",
          mt: 8,
          border: "1px solid #EDF0E1",
          borderRadius: "50px",
          p: 4,
          mx: { xs: 4, md: 4, lg: 14 },
        }}
      >
        <Grid container>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2} sx={{ py: 2 }}>
              <Grid item xs={12} md={4} sm={12}>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: {md:"45px",xs:"23px"},
                      textAlign: "left",
                      fontFamily: "Ubuntu",
                    }}
                  >
                    <span> What Our Clients Say</span>
                  </Typography>
                  <Box>
                    {/* <Box sx={{ display: "flex", pt: 2 }}>
                      <img
                        src={Vector}
                        style={{
                          width: "50px",
                          height: "50px",
                        }}
                        alt="Vector"
                      />
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "35px",
                          fontFamily: "Ubuntu",
                        }}
                      >
                        <span className="cudo-font">Trustpilot</span>
                      </Typography>
                    </Box> */}
                    <Grid item xs={2} lg={2} md={6} sx={{ pt: 4 }}>
                      <img src={Star} alt="Star" />
                    </Grid>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      pt: 4,
                      textAlign: "left",
                      fontFamily: "Ubuntu",
                    }}
                  >
                    4.7 out of 5 based on 227 reviews
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={8} sm={12}>
                <Slider {...settings}>
                  {testimonials.map((testimonial, index) => (
                    <Box key={index}>
                      <Box
                        sx={{
                          padding: "20px",
                          backgroundColor: "white",
                          border: "1px solid white",
                          borderRadius: "10px",
                          margin: "0 10px",
                          height: "250px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <Box sx={{ display: "flex", pt: 2 }}>
                            <img src={rating} alt="Rating" />
                          </Box>
                          <Typography
                            sx={{
                              textAlign: "left",
                              pt: 2,
                              fontFamily: "Ubuntu",
                              fontSize: "14px",
                              fontWeight: 400,
                              color: "#3D3D3D",
                              height: "120px",
                              overflow: "hidden",
                            }}
                          >
                            {testimonial.text}
                          </Typography>
                        </Box>
                        <Grid item xs={12} md={12}>
                          <Box sx={{ display: "flex", gap: "15px", pt: 2 }}>
                            <Box
                              sx={{
                                width: "50px",
                                height: "50px",
                                backgroundColor: "#f0f0f0",
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={
                                  testimonial.gender === "male" ? male : woman
                                }
                                alt={testimonial.gender}
                                style={{ width: "30px", height: "30px" }}
                              />
                            </Box>
                            <Typography
                              sx={{
                                fontSize: "18px",
                                fontWeight: 600,
                                pt: 2,
                                fontFamily: "Ubuntu",
                              }}
                            >
                              {testimonial.name}
                            </Typography>
                          </Box>
                        </Grid>
                      </Box>
                    </Box>
                  ))}
                </Slider>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Review;
