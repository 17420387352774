import React from "react";
import { Box, Typography } from "@mui/material";
import "../../style/style.css";

const CircleWithText = ({ text, color, borderColor }) => {
  return (
    <Box
      sx={{
        width: 110,
        height: 110,
        borderRadius: "50%",
        backgroundColor: color,
        position: "relative",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
      }}
      // className="circle-container"
    >
      <Typography
        sx={{
          fontSize: { xs: "12px", md: "13px" },
          fontFamily: "Ubuntu",
          fontWeight: "bold",
         
        }}
      >
        {text}
      </Typography>
      <Box
        className="circle-border"
        sx={{
        
          borderRadius: "50%",
          border: `1px solid ${borderColor}`,
        }}
      />
    </Box>
  );
};

export default CircleWithText;
