import React, { useState } from "react";
import { Drawer, List, ListItem, ListItemText, Button } from "@mui/material";
import { Link } from "react-router-dom";
import LoginDialog from "../HomePage/LoginDialog";

const NavDrawer = ({ drawerOpen, handleDrawerToggle, navItems }) => {
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);

  const handleLoginClick = () => {
    setLoginDialogOpen(true);
  };
  const handleDialogClose = () => {
    setLoginDialogOpen(false);
  };
  return (
    <>
      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            // width: "280px",
            backgroundColor:
              "#cfe3e0",
          },
        }}
      >
        <List>
          {navItems.map((item, index) => (
            <ListItem
              button
              key={index}
              onClick={handleDrawerToggle}
              to={item.path}
              component={Link}
            >
              <ListItemText
                primary={item.label}
                primaryTypographyProps={{ fontFamily: "Ubuntu" }}
              />
            </ListItem>
          ))}
          <ListItem>
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "#396A62",
                fontFamily: "Ubuntu",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#0B2A29",
                },
              }}
              className="header-btn"
              onClick={handleLoginClick}
            >
              Login
            </Button>
          </ListItem>
        </List>
      </Drawer>
      <LoginDialog open={loginDialogOpen} onClose={handleDialogClose} />
    </>
  );
};

export default NavDrawer;
