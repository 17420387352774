import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import EventsImg from "../assets/images/Event/Events.png";
import Evenimg from "../assets/images/Event/EventImg.png";
import Evenimg1 from "../assets/images/Event/Eventimg1.png";
import Investorimg from "../assets/images/Event/Investorimg.png";
import EventV1 from "../videos/Events/EventV1.mp4";
import EventV2 from "../videos/Events/EventV2.mp4";
import EventV3 from "../videos/Events/EventV3.mp4";
import { useNavigate } from "react-router";

function Events() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const videos = [EventV1, EventV2, EventV3];
  const navigate = useNavigate();

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 2) % videos.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 2 + videos.length) % videos.length
    );
  };
  const handleViewAll = () => {
    navigate("/view-all");
  };
  return (
    <div>
      <Grid container spacing={2} pt={4}>
        <Grid item xs={12} md={6}>
          <Box>
            <img src={EventsImg} style={{ width: "100%", height: "100%" }} />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} p={2}>
          <Box>
            <img src={Investorimg} style={{ width: "80%" }} />
            <img src={Evenimg} style={{ width: "80%" }} />
            <img src={Evenimg1} style={{ width: "80%" }} />
          </Box>
        </Grid>

        <Grid item xs={12} md={12}>
          <Box>
            <Button
              onClick={handleViewAll}
              sx={{
                backgroundColor: "#396A62",
                "&:hover": {
                  backgroundColor: "#0B2A29",
                },
                fontFamily: "Ubuntu",
                border: "1px #0B2A29",
                borderRadius: "8px",
                height: "38px",
                width: "100px",
                textTransform: "none",
                color: "white",
              }}
            >
              View All
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography
            sx={{
              fontFamily: "Ubuntu",
              fontSize: { md: "40px", xs: "25px" },
              fontWeight: 600,
              color: "#396A62",
            }}
          >
            The most innovative event <br />
            <span style={{ color: "black" }}>of the year</span>
          </Typography>
        </Grid>

        <Grid container spacing={2} pt={4}>
          <Grid item xs={6}>
            <video
              src={videos[currentIndex]}
              style={{ width: "100%" }}
              controls
            />
          </Grid>
          <Grid item xs={6}>
            <video
              src={videos[(currentIndex + 1) % videos.length]}
              style={{ width: "100%" }}
              controls
            />
          </Grid>
        </Grid>

        <Grid container justifyContent="center" pt={2}>
          <Button
            onClick={handlePrev}
            disabled={currentIndex === 0}
            sx={{
              mx: 2,
              backgroundColor: "#396A62",
              color: "white",
              borderRadius: "50%",
              minWidth: "40px",
              height: "40px",
              "&:hover": {
                backgroundColor: "#639a91",
              },
            }}
          >
            &larr;
          </Button>
          <Button
            onClick={handleNext}
            disabled={currentIndex >= videos.length - 2}
            sx={{
              mx: 2,
              backgroundColor: "#396A62",
              color: "white",
              borderRadius: "50%",
              minWidth: "40px",
              height: "40px",
              "&:hover": {
                backgroundColor: "#639a91",
              },
            }}
          >
            &rarr;
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default Events;
