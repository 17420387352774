import {
  Box,
  Grid,
  Link,
  styled,
  TextField,
  Typography,
  InputAdornment,
  Button,
} from "@mui/material";
import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router";

const CustomTextField = styled(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderRadius: "8px",
    },
  },
  "& .MuiInputLabel-root": {
    fontSize: "15px",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    fontSize: "15px",
  },
  "& .MuiInputBase-input": {
    fontSize: "15px",
  },
}));

function Index() {
  const handleOpenDematClick = () => {
    window.location.href = "https://ekyc.arhamshare.com/";
  };
  return (
    <div>
      <Grid
        container
        sx={{
          backgroundColor: "#DBE7E5",
          py: 4,
          px: { xs: 4, md:20 },
          mt: 10,
          scrollbarWidth: "none",
          overflowY: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sm={12}>
            <Grid container spacing={4} sx={{ pt: 1 }}>
              <Grid item xs={12} md={6} sm={12} sx={{ textAlign: "left" }}>
                <Typography
                  sx={{
                    fontSize: { md: "40px", xs: "26px" },
                    fontWeight: 500,
                    fontFamily: "Ubuntu",
                  }}
                >
                  Open your{" "}
                  <span style={{ color: "#2D5A53" }}>demat account</span>
                </Typography>

                <Typography
                  sx={{
                    fontSize: "15px",
                    pt: 1,
                    fontFamily: "Ubuntu",
                  }}
                >
                  Start your financial journey with Arham Share.
                </Typography>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  sx={{ pt: 2, display: "block" }}
                >
                  <Grid item>
                    {/* <CustomTextField
                      type="number"
                      size="small"
                      placeholder="Enter Mobile Number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{ color: "black", fontFamily: "Ubuntu" }}
                          >
                            +91
                          </InputAdornment>
                        ),
                      }}
                    /> */}
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<ArrowForwardIcon sx={{ fontSize: "30px" }} />}
                      sx={{
                        backgroundColor: "#396A62",
                        "&:hover": {
                          backgroundColor: "#0B2A29",
                        },
                        fontFamily: "Ubuntu",
                        border: "1px #0B2A29",
                        borderRadius: "8px",
                        height: "38px",
                        textTransform: "none",
                      }}
                      className="header-btn"
                      type="submit"
                      onClick={handleOpenDematClick}
                    >
                      <span style={{ fontSize: "13px" }}>
                        Open Demat Account
                      </span>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Index;
