import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { motion } from "framer-motion";
import Naresh from "../../assets/images/Director/Naresh.png";
import Priyank from "../../assets/images/Director/Priyank.png";
import Rushit from "../../assets/images/Director/Rushit.png";
import Yash from "../../assets/images/Director/Yash.png";
import LinkedIn from "../../assets/images/Icon/LinkedIn.png";

function Index() {
  return (
    <div>
      <Grid
        container
        sx={{
          background:
            "linear-gradient(180deg, #f9fffe 0%, #edf0ef 50%, #E4F0EE 100%)",
          px: { xs: 4, md: 20},
          py: { xs: 2, md: 4, lg: 6 },
          scrollbarWidth: "none",
          overflowY: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontSize: { md: "40px", xs: "22px" },
                    fontWeight: 500,
                    fontFamily: "Ubuntu",
                    pt: 2,
                  }}
                >
                  Masterminds Behind Arham Share’s Success
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ pt: 8 }}>
              <Grid item xs={12} md={3}>
                <Box sx={{ position: "relative" }}>
                  <motion.img
                    src={Naresh}
                    whileHover={{ scale: 1.1 }}
                    style={{ width: "60%", paddingTop: "15px" }}
                  />

                  <Typography
                    sx={{
                      color: "#2D5A53",
                      fontWeight: "600",
                      fontFamily: "Ubuntu",
                      fontSize: "20px",
                      pt: 2,
                    }}
                  >
                    Mr. Naresh Mehta
                  </Typography>
                  <Typography sx={{ fontFamily: "Ubuntu", fontSize: "15px" }}>
                    (Managing Director)
                  </Typography>
                  <Typography>
                    <a
                      href="https://www.linkedin.com/in/naresh-mehta-5911a5109/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={LinkedIn}
                        alt="LinkedIn"
                        style={{
                          width: "22px",
                          height: "22px",
                        }}
                      />
                    </a>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box sx={{ position: "relative" }}>
                  <motion.img
                    src={Priyank}
                    whileHover={{ scale: 1.1 }}
                    style={{ width: "60%", paddingTop: "15px" }}
                  />

                  <Typography
                    sx={{
                      color: "#2D5A53",
                      fontWeight: "600",
                      fontFamily: "Ubuntu",
                      fontSize: "20px",
                      pt: 2,
                    }}
                  >
                    Mr. Priyank Mehta
                  </Typography>
                  <Typography sx={{ fontFamily: "Ubuntu", fontSize: "15px" }}>
                    (Director)
                  </Typography>
                  <Typography>
                    <a
                      href="https://www.linkedin.com/in/priyank-mehta-4a920b89/"
                      // target="_blank"
                      // rel="noopener noreferrer"
                    >
                      <img
                        src={LinkedIn}
                        alt="LinkedIn"
                        style={{
                          width: "22px",
                          height: "22px",
                        }}
                      />
                    </a>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box sx={{ position: "relative" }}>
                  <motion.img
                    src={Rushit}
                    whileHover={{ scale: 1.1 }}
                    style={{ width: "60%", paddingTop: "20px" }}
                  />

                  <Typography
                    sx={{
                      color: "#2D5A53",
                      fontWeight: "600",
                      fontFamily: "Ubuntu",
                      fontSize: "20px",
                      pt: 2,
                    }}
                  >
                    Mr. Rushit Mehta
                  </Typography>
                  <Typography sx={{ fontFamily: "Ubuntu", fontSize: "15px" }}>
                    (Director)
                  </Typography>
                  <Typography>
                    <a
                      href="https://www.linkedin.com/in/rushit-mehta-2a7b38112/"
                      target="_blank"
                      // rel="noopener noreferrer"
                    >
                      <img
                        src={LinkedIn}
                        alt="LinkedIn"
                        style={{
                          width: "22px",
                          height: "22px",
                        }}
                      />
                    </a>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box sx={{ position: "relative" }}>
                  <motion.img
                    src={Yash}
                    whileHover={{ scale: 1.1 }}
                    style={{ width: "60%", paddingTop: "20px" }}
                  />

                  <Typography
                    sx={{
                      color: "#2D5A53",
                      fontWeight: "600",
                      fontFamily: "Ubuntu",
                      fontSize: "20px",
                      pt: 2,
                    }}
                  >
                    Mr. Yash Mehta
                  </Typography>
                  <Typography sx={{ fontFamily: "Ubuntu", fontSize: "15px" }}>
                    (Director)
                  </Typography>
                  <Typography>
                    <a
                      href="https://www.linkedin.com/in/yashnmehta1/"
                      // target="_blank"
                      // rel="noopener noreferrer"
                    >
                      <img
                        src={LinkedIn}
                        alt="LinkedIn"
                        style={{
                          width: "22px",
                          height: "22px",
                        }}
                      />
                    </a>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Index;
