import React, { useState } from "react";
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router";
import Header from "../Component/HomePage/Header";
import Fotter from "../Component/Footer";
import DownloadIcon from "../assets/images/IPOs/Download.png";
import axios from "axios";

function KycForm() {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(
    "Account opening Form and Annexures"
  );
  const [loading, setLoading] = useState(false); // Loading state
  const [progress, setProgress] = useState(0); // Progress state

  const handleBreadcrumbClick = (event, path) => {
    event.preventDefault();
    navigate(path);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handleDownloadClick = async (fileIdentifier) => {
    setLoading(true); // Show the loader
    setProgress(0); // Reset progress

    try {
      const downloadUrl = `${process.env.REACT_APP_API_BASE_URL}/arham_web/v1/fetch-document/?section=kyc_forms&sub_section=${selectedCategory}&file_identifier=${fileIdentifier}`;
      const response = await fetch(downloadUrl, { method: "GET" });

      if (!response.ok) {
        throw new Error(`Download failed with status ${response.status}`);
      }

      const blob = await response.blob();
      const contentType = response.headers.get("content-type");

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      const extension = getExtension(contentType);
      link.download = `${fileIdentifier}.${extension}`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Simulate progress update
      for (let i = 1; i <= 100; i++) {
        setTimeout(() => setProgress(i), i * 10); // Simulated progress increment
      }
    } catch (error) {
      console.error("Download error:", error);
    } finally {
      setTimeout(() => {
        setLoading(false); // Hide the loader
        setProgress(0); // Reset progress
      }, 1000); // Give time for the progress to show
    }
  };
  const getExtension = (contentType) => {
    switch (contentType) {
      case "application/pdf":
        return "pdf";
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "docx";
      case "application/msword":
        return "doc";
      default:
        return "";
    }
  };

  const kycDataMap = {
    "Account opening Form and Annexures": [
      { title: "Individual KYC Form", file_identifier: "Individual KYC Form" },
      {
        title: "Individual Additional KYC page",
        file_identifier: "Individual Additional KYC page",
      },
      {
        title: "Non Individual Additional KYC page (HUF)",
        file_identifier: "Non Individual Additional KYC page (HUF)",
      },
      { title: "Non Individual", file_identifier: "Non Individual" },
      {
        title: (
          <>
            Non Individual ( Corporate / partnership / LLP Additional KYC page )
          </>
        ),
        file_identifier:
          "Non Individual (Corporate / partnership / LLP Additional KYC page)",
      },
      {
        title: "Activation of MTF facility in my Trading Account",
        file_identifier: "Activation of MTF facility in my Trading Account",
      },
      {
        title: "KRA Bank Verification Letter",
        file_identifier: "KRA Bank Verification Letter",
      },
      {
        title: "Mode of operation-Incase joint holder",
        file_identifier: "Mode of operation-Incase joint holder",
      },
      {
        title: "Partnership firm letter head format",
        file_identifier: "Partnership firm letter head format",
      },
      {
        title: "Additional documents for Corporate accounts",
        file_identifier: "Additional documents for Corporate accounts",
      },
      {
        title: "Additional documents for LLP accounts",
        file_identifier: "Additional documents for LLP accounts",
      },
    ],

    "KYC and other forms": [
      {
        title: "Modification Request Form",
        file_identifier: "Modification Request Form",
      },
      {
        title: "Mobile & Email Modification Request Form",
        file_identifier: "Mobile & Email Modification Request Form",
      },
      {
        title: "Closure request Form for Demat & Trading Account",
        file_identifier: "Closure request Form for Demat & Trading Account",
      },
      {
        title: "Application Form for Change of Signature Form",
        file_identifier: "Application For Change of Signature Form",
      },
      {
        title: "Brokerage modification Form",
        file_identifier: "Brokerage modification Form",
      },
      {
        title: "Consent letter form for DRF",
        file_identifier: "Consent letter form for DRF",
      },
      {
        title: "DDPI - Demat Debit Pledge Instruction",
        file_identifier: "DDPI-Demat Debit Pledge Instruction",
      },
      {
        title: "Dematerialization Request Form ( Mutual Funds Units )",
        file_identifier: "Dematerialization Request Form (Mutual Funds Units)",
      },
      {
        title: "Freeze Unfreeze Request Form",
        file_identifier: "Freeze Unfreeze Request Form",
      },
      {
        title: "BANK Signature Verification Letter (KRA)",
        file_identifier: "BANK Signature Verification Letter (KRA)",
      },
      {
        title:
          "Letter to Add Delete Beneficiary details for executing ‘OFF-MARKET’ transaction",
        file_identifier:
          "Letter to Add  Delete Beneficiary details for executing ‘OFF-MARKET’ transaction",
      },
      {
        title: "Issuance of DIS letter for incase of Loss of DIS booklet)",
        file_identifier:
          "Issuance of DIS letter for incase of Loss of DIS booklet",
      },
      {
        title:
          "Correction in the name of individual demat account holder (Minor correction)",
        file_identifier:
          "Correction in the name of individual demat account holder (Minor correction)",
      },
      {
        title: "Mutual Fund Destatementisation Request Form ( MF DRF )",
        file_identifier: "Mutual Fund Destatementisation Request Form(MF DRF)",
      },
      {
        title: "Mutual Fund Restatementization Request Form [MF-RRF]",
        file_identifier: "Mutual Fund Restatementization Request Form [MF-RRF]",
      },
      { title: "DP Tarrif Sheet", file_identifier: "DP Tarrif Sheet" },
      {
        title: "Nomination Request Form",
        file_identifier: "Nomination Request Form",
      },
      {
        title: "Declaration Form For Opting Out of Nomination",
        file_identifier: "Declaration Form For Opting Out of Nomination",
      },
      {
        title:
          "Combined Annexure form for relation declaration and payment particulars",
        file_identifier:
          "Combined Annexure form for relation declaration and payment particulars",
      },
      { title: "Pledge Request Form", file_identifier: "Pledge Request Form" },
      {
        title: "Remateriallstion Request Form",
        file_identifier: "Remateriallstion Request Form",
      },
      {
        title:
          "Requistion Form For issuance of First Delievery Instruction Slip (DIS) Book",
        file_identifier:
          "Requistion Form For issuance of First Delievery Instruction Slip (DIS) Book",
      },
      {
        title: "Segment addition form",
        file_identifier: "segment addition form",
      },
      {
        title: "Signature Verifaction Letter - Demat Rejection",
        file_identifier: "Signature Verify Letter-Demat Rejection",
      },
      {
        title: "Trading preference declaration",
        file_identifier: "Trading preference declaration",
      },
      {
        title:
          "Transmission Request Form (In case of death of one - more of the joint holders)",
        file_identifier:
          "Transmission Request Form (In case of death of one - more of the joint holders)",
      },
      {
        title:
          "Transmission Request Form (In case of death of the sole holder)",
        file_identifier:
          "Transmission Request Form (In case of death of the sole holder)",
      },
      {
        title: "Transmission Cum dematerialization request Form ( part of DRF)",
        file_identifier:
          "Transmission Cum dematerialization request Form ( part of DRF)",
      },
      {
        title: "Tranposition Form (TRPF) ( Part of DRF )",
        file_identifier: "Tranposition Form (TRPF) ( Part of DRF )",
      },
      {
        title: "UBO- Declaration Form",
        file_identifier: "UBO- Declaration Form",
      },
      {
        title: "Unpledge Request Form",
        file_identifier: "Unpledge Request Form",
      },
    ],

    CheckList: [
      {
        title: "Corporate Account",
        file_identifier: "Corporate Account",
      },
      {
        title: "LLP Account",
        file_identifier: "LLP Account",
      },
      {
        title: "HUF Account",
        file_identifier: "HUF Account",
      },
      {
        title: "Individual Account",
        file_identifier: "Individual Account",
      },
      {
        title: "Minor Account",
        file_identifier: "Minor Account",
      },
      {
        title: "NRI Account",
        file_identifier: "NRI Account",
      },
      {
        title: "Partnership Account",
        file_identifier: "Partnership Account",
      },
      {
        title: "Trust Account",
        file_identifier: "Trust Account",
      },
    ],
  };

  return (
    <>
      <Header />

      <Grid
        container
        sx={{
          background:
            "linear-gradient(180deg, #ffffff 0%, #c1d8d4 0%, #ffffff 40%)",
          px: { xs: 4, md: 20 },
        }}
      >
        <Grid container pt={6}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ margin: "auto 0", textAlign: "center" }}
          >
            <Typography
              sx={{
                fontSize: "55px",
                fontFamily: "Ubuntu",
                fontWeight: 500,
              }}
            >
              KYC Forms
            </Typography>
            {/* <Breadcrumbs
              aria-label="breadcrumb"
              separator=">"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Link
                color="inherit"
                href="/"
                onClick={(event) => handleBreadcrumbClick(event, "/")}
                sx={{
                  cursor: "pointer",
                  textDecoration: "none",
                  fontFamily: "Ubuntu",
                  fontWeight: 500,
                  fontSize: "25px",
                  color: "#3D3D3D",
                }}
              >
                Home
              </Link>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontWeight: 400,
                  fontSize: "25px",
                  color: "#3D3D3D",
                }}
              >
                KYC Forms
              </Typography>
            </Breadcrumbs> */}
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} sx={{ pt: 6 }}>
          <Typography
            sx={{
              fontSize: "35px",
              fontFamily: "Ubuntu",
              fontWeight: 500,
              textAlign: "left",
              color: "#396A62",
            }}
          >
            {/* KYC Form Downloads */}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {[
            "Account opening Form and Annexures",
            "KYC and other forms",
            "CheckList",
          ].map((category) => (
            <Grid item key={category}>
              <Button
                variant="contained"
                onClick={() => handleCategoryClick(category)}
                sx={{
                  backgroundColor:
                    selectedCategory === category ? "#396A62" : "#ffffff",
                  color: selectedCategory === category ? "#ffffff" : "#396A62",
                  border: "1px solid #396A62",
                  fontFamily: "Ubuntu",
                  fontWeight: 500,
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#396A62",
                    color: "#ffffff",
                  },
                }}
              >
                {category}
              </Button>
            </Grid>
          ))}
        </Grid>

        {/* Download Cards */}
        <Grid container spacing={2} sx={{ pt: 8 }}>
          {kycDataMap[selectedCategory].map((file, index) => (
            <Grid item xs={12} sm={12} md={3} key={index}>
              <Box
                sx={{
                  textAlign: "center",
                  border: "1px solid #E0E0E0",
                  borderRadius: "8px",
                  height: "100%",
                  my: 1,
                  "&:hover": {
                    backgroundColor: "#E0E0E0",
                  },
                }}
              >
                <Box sx={{ pt: 2 }}>
                  <img
                    src={DownloadIcon}
                    alt={`${file.file_name} Download`}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDownloadClick(file.file_identifier)} 
                  />
                </Box>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: { xs: "12px", md: "14px" },
                    color: "#3D3D3D",
                    mt: 2,
                    px: 2,
                    wordBreak: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  {file.title}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        {loading && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 9999,
            }}
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 100,
                height: 100,
              }}
            >
              <CircularProgress
                color="inherit"
                sx={{
                  position: "absolute",
                  color: "#B0B0B0",
                }}
                value={progress}
                size={60}
                thickness={4}
              />
              <Typography
                variant="body1"
                sx={{
                  position: "absolute",
                  fontSize: "18px",
                  fontWeight: 500,
                }}
              >
                {progress}%
              </Typography>
            </Box>
          </Box>
        )}
      </Grid>

      <Fotter />
    </>
  );
}

export default KycForm;
