import React, { createContext, useState, useContext } from 'react';

// Create a Context for the blog data
const BlogContext = createContext();

// Create a provider component
export const BlogProvider = ({ children }) => {
  const [blogs, setBlogs] = useState([]);

  return (
    <BlogContext.Provider value={{ blogs, setBlogs }}>
      {children}
    </BlogContext.Provider>
  );
};

// Custom hook to use the BlogContext
export const useBlog = () => useContext(BlogContext);
