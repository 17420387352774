  import React from "react";
  import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
  import InvestorCorner from "../Investor-Corner/index";
  import ContactUs from "../Contact-Us/index";
  import HomePage from "../Component/HomePage";
  import BecomePartner from "../Become-Partner/index";
  import Product from "../Product-Services/index";
  import Faqs from "../Faqs/index";
  import ErrorPage from "../ErrorPage/index";
  import Blog from "../Blog/index";
  import NotFoundPage from "../ErrorPage/index";
  import IPOs from "../IPOs/index";
  import ScrollToTop from "../Routes/ScrollToTop";
  import BuyBack from "../BuyBack/index";
  import BlogDetail from "../BlogDetails/Details";
  import Download from "../Download/index";
  import Technical from "../Download/Technical";
  import KycForm from "../Download/KycForm";
  import Others from "../Download/Others";
  import Feedback from "../Feedback/index";
  import ViewAll from "../Event/ViewAll";
  import Career from "../Careers/index";
  import Event from "../Event/index";

  function AppRoutes() {
    return (
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/become-partner" element={<BecomePartner />} />
          <Route path="/services" element={<Product />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/investor's-Charter" element={<InvestorCorner />} />
          <Route path="/help-support" element={<ContactUs />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/event" element={<Event />} />
          <Route path="/ipos" element={<IPOs />} />
          <Route path="buyback" element={<BuyBack />} />
          <Route path="download" element={<Download />} />
          <Route path="technical" element={<Technical />} />
          <Route path="kyc-forms" element={<KycForm />} />
          <Route path="others" element={<Others />} />
          <Route path="feedback" element={<Feedback />} />
          <Route path="/view-all" element={<ViewAll />} />
          <Route path="/careers" element={<Career />} />
          <Route path="/blog/:id" element={<BlogDetail />} />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    );
  }

  export default AppRoutes;
