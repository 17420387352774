import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Event1 from "../assets/images/Event/AllEvent/Event1.png";
import Event2 from "../assets/images/Event/AllEvent/Event2.png";
import Event3 from "../assets/images/Event/AllEvent/Event3.png";
import Photo1 from "../assets/images/Event/AllEvent/Photo1.png";
import Photo2 from "../assets/images/Event/AllEvent/Photo2.png";
import Photo3 from "../assets/images/Event/AllEvent/Photo3.png";

function AllEvents() {
  const [selectedCategory, setSelectedCategory] = useState("All");

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };
  const events = [
    {
      name: "Investors Summit Surat 2024",
      date: "15th-16th March 2024",
      image: Event1,
    },
    {
      name: "Investors Summit Surat 2024",
      date: "15th-16th March 2024",
      image: Event2,
    },
    {
      name: "Investors Summit Surat 2024",
      date: "15th-16th March 2024",
      image: Event3,
    },
    {
      name: "Investors Summit Surat 2024",
      date: "15th-16th March 2024",
      image: Event2,
    },
    {
      name: "Investors Summit Surat 2024",
      date: "15th-16th March 2024",
      image: Event3,
    },
    {
      name: "Investors Summit Surat 2024",
      date: "15th-16th March 2024",
      image: Event2,
    },
    {
      name: "Investors Summit Surat 2024",
      date: "15th-16th March 2024",
      image: Event1,
    },
    {
      name: "Investors Summit Surat 2024",
      date: "15th-16th March 2024",
      image: Event2,
    },
    {
      name: "Investors Summit Surat 2024",
      date: "15th-16th March 2024",
      image: Event3,
    },
    {
      name: "Investors Summit Surat 2024",
      date: "15th-16th March 2024",
      image: Event1,
    },
    {
      name: "Investors Summit Surat 2024",
      date: "15th-16th March 2024",
      image: Event2,
    },
    {
      name: "Investors Summit Surat 2024",
      date: "15th-16th March 2024",
      image: Event3,
    },
    // Add more events as needed
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12} sx={{ pt: 6 }}>
          <Typography
            sx={{
              fontSize: "32px",
              fontFamily: "Ubuntu",
              fontWeight: 500,
              textAlign: "left",
              color: "#396A62",
            }}
          >
            All Events
          </Typography>
        </Grid>

        <Grid container spacing={2}>
          {["Up Coming", "Current", "Past", "All"].map((category) => (
            <Grid item key={category}>
              <Button
                variant="contained"
                onClick={() => handleCategoryClick(category)} // Handle click
                sx={{
                  backgroundColor:
                    selectedCategory === category ? "#396A62" : "#ffffff",
                  color: selectedCategory === category ? "#ffffff" : "#396A62",
                  border: "1px solid #396A62",
                  fontFamily: "Ubuntu",
                  fontWeight: 500,
                  "&:hover": {
                    backgroundColor: "#396A62",
                    color: "#ffffff",
                  },
                }}
              >
                {category}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid container spacing={2} pt={4}>
        {events.map((event, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <CardMedia
              component="img"
              //   height="140"
              image={event.image}
              alt={event.name}
            />
            <CardContent>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontWeight: 600,
                  color: "#396A62",
                  textAlign: "left",
                }}
              >
                {event.date}
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  fontFamily: "Ubuntu",
                  fontWeight: 600,
                  fontSize: "18px",
                  textAlign: "left",
                }}
              >
                {event.name}
              </Typography>
            </CardContent>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2} pt={4}>

        
      </Grid>
    </>
  );
}

export default AllEvents;
