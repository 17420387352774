import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import arhamApi from "../../assets//images/arhamApi.png";
import Colocation from "../../assets/images/ArhamImg/Co-location.png";
import Stock from "../../assets/images/Icon/Stock.png";
import Pin from "../../assets/images/Icon/pin.png";
import Currency from "../../assets/images/Icon/Currency.png";
import Customize from "../../assets/images/Icon/Customize.png";
import Platform from "../../assets/images/Icon/Platform.png";
import Risk from "../../assets/images/Icon/Risk.png";
import AnimatedImage from "../Perks/AnimatedImage ";
import AnimatedGrid from "../Perks/AnimatedGrid";
import Symp from "../../assets/images/logo/Symp.png";
import Greek from "../../assets/images/logo/Greek.png";
import Multi from "../../assets//images/logo/Multi.png";
import Saral from "../../assets/images/logo/Saral.png";
import Moons from "../../assets//images/logo/Moon.png";
import BenefitsCircles from "./BenefitsLayout ";
import "../../style/style.css";

function index() {
  const features = [
    "No Hidden Cost",
    "Own Strategies",
    "Flexible APIs",
    "Instant Execution",
    "Powerful Trading Platforms",
  ];
  const half = Math.ceil(features.length / 2);
  const leftColumn = features.slice(0, half);
  const rightColumn = features.slice(half);

  return (
    <div>
      <Grid
        container
        sx={{
          px: { xs: 4, md:20 },
        }}
      >
        <Grid container spacing={2} sx={{ mt: 8 }}>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid container spacing={2} sx={{ textAlign: "left" }}>
                <Grid item xs={12} md={6} sx={{ margin: "auto 0" }}>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "24px",
                        sm: "52px",
                        md: "35px",
                      },
                      fontWeight: 500,
                      fontFamily: "Ubuntu",
                    }}
                  >
                    <span>Why Choose Arham's API?</span>
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { md: "20px", xs: "18px" },
                      fontWeight: 600,
                      fontFamily: "Ubuntu",
                      color: "#2D5A53",
                      pt: 2,
                    }}
                  >
                    <span>Key Features of Arham's API</span>
                  </Typography>
                  <Grid container sx={{ pt: 2 }} spacing={2}>
                    <Grid item xs={6}>
                      <List>
                        {leftColumn.map((feature, index) => (
                          <ListItem
                            key={index}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              pl: 0,
                            }}
                          >
                            <CircleIcon
                              sx={{ fontSize: "12px", color: "#2D5A53", mr: 1 }}
                            />
                            <ListItemText
                              primary={feature}
                              primaryTypographyProps={{
                                fontSize: { md: "18px", xs: "13px" },
                                fontFamily: "Ubuntu",
                              }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                    <Grid item xs={6}>
                      <List>
                        {rightColumn.map((feature, index) => (
                          <ListItem
                            key={index}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              pl: 0,
                            }}
                          >
                            <CircleIcon
                              sx={{ fontSize: "12px", color: "#2D5A53", mr: 1 }}
                            />
                            <ListItemText
                              primary={feature}
                              primaryTypographyProps={{
                                fontSize: { md: "18px", xs: "12px" },

                                fontFamily: "Ubuntu",
                              }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <BenefitsCircles />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ pt: 14 }}>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid container spacing={2} sx={{ textAlign: "left" }}>
                <Grid item xs={12} md={6} sm={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: {
                        xs: "flex-start",
                        xs: "center",
                        md: "flex-start",
                      },
                    }}
                  >
                    <img src={Colocation} style={{ width: "60%" }} />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{ margin: "auto 0" }}>
                  <Typography
                    sx={{
                      fontSize: { md: "38px", sm: "25px", xs: "22px" },
                      fontWeight: 500,
                      fontFamily: "Ubuntu",
                      position: "relative",
                      display: "inline-block",
                    }}
                    className="hover-text"
                  >
                    <span className="hover-char">U</span>pgrade {" "}
                    <span className="hover-char">Y</span>our{" "}
                    <span className="hover-char">T</span>rading {" "}
                    {/* <span className="hover-char">T</span>rading{" "} */}
                    <span className="hover-char">W</span>ith{" "}
                    <span className="hover-char">O</span>ur{" "}
                    <span className="hover-char">C</span>o-location{" "}
                    <span className="hover-char">S</span>ervices
                  </Typography>

                  <Typography
                    sx={{
                      pt: 2,
                      fontSize: { md: "14px" },
                      //   fontWeight: 500,
                      fontFamily: "Ubuntu",
                      color: "#3D3D3D",
                    }}
                  >
                    Co-location allows ultra fast connectivity and faster
                    execution of trades which can be as quick as milliseconds.
                    Our state-of-the-art co-location infrastructure ensures a
                    seamless and efficient trading experience, eliminating any
                    delays in your fast-paced transactions.
                  </Typography>
                  <Typography
                    sx={{
                      color: "#2D5A53",
                      pt: 4,
                      pb:4,
                      fontWeight: "600",
                      fontFamily: "Ubuntu",
                      fontSize: { md: "18px", xs: "22px" },
                    }}
                  >
                    Benefits of Co-location
                  </Typography>
                  <Grid container spacing={2} sx={{ pt: 2 }}>
                    <Grid item xs={6}>
                      <Box display="flex" alignItems="center">
                        <img src={Stock} alt="Logo" height="50px" />
                        <Typography
                          sx={{
                            fontWeight: 500,
                            color: "#3D3D3D",
                            fontFamily: "Ubuntu",
                            pl: 2,
                            textAlign: "left",
                            fontSize: { md: "16px", xs: "14px" },
                          }}
                        >
                          Superfast Trading
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex" alignItems="center">
                        <img src={Pin} alt="Logo" height="50px" />
                        <Typography
                          sx={{
                            fontWeight: 500,
                            color: "#3D3D3D",
                            fontFamily: "Ubuntu",
                            pl: 2,
                            textAlign: "left",
                            fontSize: { md: "16px", xs: "14px" },
                          }}
                        >
                          Reliable Connectivity
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex" alignItems="center">
                        <img src={Customize} alt="Logo" height="50px" />
                        <Typography
                          sx={{
                            fontWeight: 500,
                            color: "#3D3D3D",
                            fontFamily: "Ubuntu",
                            pl: 2,
                            textAlign: "left",
                            fontSize: { md: "16px", xs: "14px" },
                          }}
                        >
                          Best in Class Infrastructure
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex" alignItems="center">
                        <img src={Platform} alt="Logo" height="50px" />
                        <Typography
                          sx={{
                            fontWeight: 500,
                            color: "#3D3D3D",
                            fontFamily: "Ubuntu",
                            pl: 2,
                            textAlign: "left",
                            fontSize: { md: "16px", xs: "14px" },
                          }}
                        >
                          Dedicated Servers
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex" alignItems="center">
                        <img src={Currency} alt="Logo" height="50px" />
                        <Typography
                          sx={{
                            fontWeight: 500,
                            color: "#3D3D3D",
                            fontFamily: "Ubuntu",
                            pl: 2,
                            textAlign: "left",
                            fontSize: { md: "16px", xs: "14px" },
                          }}
                        >
                          Opportunity in Arbitrage
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex" alignItems="center">
                        <img src={Risk} alt="Logo" height="50px" />
                        <Typography
                          sx={{
                            fontWeight: 500,
                            color: "#3D3D3D",
                            fontFamily: "Ubuntu",
                            pl: 2,
                            textAlign: "left",
                            fontSize: { md: "16px", xs: "14px" },
                          }}
                        >
                          Risk Management
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid sx={{ pt: 12 }}>
          <AnimatedGrid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      style={{
                        fontSize: "40px",
                        fontWeight: 500,
                        fontFamily: "Ubuntu",
                      }}
                      className="app-font"
                    >
                      <span className="app-font">
                        Famous Platforms We Are Integrated With
                      </span>
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Ubuntu",
                        fontSize: "15px",
                        color: "#3D3D3D",
                        pt: 4,
                      }}
                    >
                      These are some of the well-known trading partners that we
                      have tied hands with, so Arham Share can provide you with
                      the greatest trading or investment experience possible.
                      Once you get onboard with us, we will be able to provide
                      you with even more trading opportunities.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ pt: 2 }}>
                  <Grid item xs={12} md={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        pt: 4,
                      }}
                    >
                      <AnimatedImage
                        src={Symp}
                        style={{
                          width: "16%",
                          height: "55px",
                        }}
                        className="image-platform"
                      />
                      <AnimatedImage
                        src={Greek}
                        style={{
                          width: "16%",
                          height: "55px",
                        }}
                        className="image-platform"
                      />
                      <AnimatedImage
                        src={Multi}
                        style={{
                          width: "16%",
                          height: "55px",
                        }}
                        className="image-platform"
                      />
                      <AnimatedImage
                        src={Saral}
                        style={{
                          width: "16%",
                          height: "55px",
                        }}
                        className="image-platform"
                      />
                      <AnimatedImage
                        src={Moons}
                        style={{
                          width: "16%",
                          height: "55px",
                        }}
                        className="image-platform"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AnimatedGrid>
        </Grid>
      </Grid>
    </div>
  );
}

export default index;
