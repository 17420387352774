import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Nilesh from "../../assets/images/Director/Nilesh.png";
import Deval from "../../assets/images/Director/Deval.png";
import Chintan from "../../assets/images/Director/Chintan.png";
import DIXIT from "../../assets/images/Director/DIXIT.png";
import Hiren from "../../assets/images/Director/Hiren.png";
import Jaydeep from "../../assets/images/Director/Jaydeep.png";
import Lokesh from "../../assets/images/Director/Lokesh.png";
import Nirav from "../../assets/images/Director/Nirav.png";
import Prashant from "../../assets/images/Director/Prashant.png";
import Pratik from "../../assets/images/Director/Pratik.png";
import Rakesh from "../../assets/images/Director/Rakesh.png";
import Rohit from "../../assets/images/Director/Rohit.png";
import TanviChauhan from "../../assets/images/Director/TanviChauhan.png";
import "../../style/style.css";

function Department() {
  const departments = [
    { name: "Mr. Nilesh Mistry", title: "Accounts", image: Nilesh },
    { name: "Mr. Deval Shah", title: "Compliance", image: Deval },
    { name: "Mr. Chintan Desai", title: "Securities", image: Chintan },
    { name: "Mr. Dixit Joshi", title: "WMS", image: DIXIT },
    { name: "Mr. Hiren Naik", title: "B2B Sales", image: Hiren },
    { name: "Mr. JayDeep Savaj", title: "Digital Marketing", image: Jaydeep },
    {
      name: "Mr. Lokesh Panpaliya",
      title: "B2C sales & Dealing",
      image: Lokesh,
    },
    { name: "Mr. Nirav Desai", title: "RMS", image: Nirav },
    { name: "Mr. Prashant Barwaliya", title: "Research Head", image: Prashant },
    { name: "Mr. Pratik Lineshwala", title: "Customer Care", image: Pratik },
    { name: "Mr. Rakesh Patel", title: "DP KYC", image: Rakesh },
    { name: "Mr. Rohit Vishwakarma", title: "IT", image: Rohit },
    { name: "Ms. Tanvi Chauhan", title: "HR", image: TanviChauhan },
  ];

  return (
    <div className="marquee">
      <Grid item xs={12} md={12}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Typography
              sx={{
                fontSize: { md: "40px", xs: "24px" },
                fontWeight: 500,
                fontFamily: "Ubuntu",
                mt: 8,
              }}
            >
              Masters of Their Domains
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} sx={{ mt: 4 }}>
            <div className="marquee-content">
              {departments.map((department, index) => (
                <Box
                  key={index}
                  // className="marquee-item"
                  sx={{
                    display: "inline-block",
                    marginRight: "20px",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={department.image}
                    alt={department.name}
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "50%",
                      marginTop: "4px",
                      display: "inline-block",
                      verticalAlign: "middle",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 500,
                      fontFamily: "Ubuntu",
                      color: "#2D5A53",
                      mt: 1,
                      // textWrap: "wrap",
                    }}
                  >
                    {department.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Ubuntu",
                      mt: 0.5,
                    }}
                  >
                    ({department.title})
                  </Typography>
                </Box>
              ))}
              {departments.map((department, index) => (
                <Box
                  key={`duplicate-${index}`}
                  sx={{
                    display: "inline-block",
                    marginRight: "20px",
                    textAlign: "center",
                  }}
                  //  className="marquee-item"
                >
                  <img
                    src={department.image}
                    alt={department.name}
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "50%",
                      marginTop: "4px",
                      display: "inline-block",
                      verticalAlign: "middle",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 500,
                      fontFamily: "Ubuntu",
                      color: "#2D5A53",
                      mt: 1,
                      // textWrap: "wrap",
                    }}
                  >
                    {department.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Ubuntu",
                      mt: 0.5,
                    }}
                  >
                    ({department.title})
                  </Typography>
                </Box>
              ))}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Department;
