import { motion } from "framer-motion";
import { Box } from "@mui/material";

const HoverGridItem = ({ children, style }) => {
  return (
    <motion.div
      initial={{ scale: 1 }}
      whileHover={{ scale: 1.05 }}
      transition={{ duration: 0.3 }}
      style={{ ...style, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Box>
        {children}
      </Box>
    </motion.div>
  );
};

export default HoverGridItem;
