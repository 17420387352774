import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Typography, Box, CircularProgress } from "@mui/material";
import Header from "../Component/HomePage/Header";
import Fotter from "../Component/Footer";
import Instagram from "../assets/images/Blog/instagram.png";
import LinkedIn from "../assets/images/Blog/linkedin.png";
import YouTube from "../assets/images/Blog/YouTube.png"
import Facebook from "../assets/images/Blog/Facebook.png";

function Details() {
  const location = useLocation();
  const { blog } = location.state || {};
  const [recentPosts, setRecentPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch("http://192.168.119.192:3020/arham_web/v1/fetch-blogs/", {
      headers: {
        source: "arham_web",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRecentPosts(data.message);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  const today = new Date();
  const fiveDaysAgo = new Date();
  fiveDaysAgo.setDate(today.getDate() - 6);

  const filteredRecentPosts = recentPosts.filter((post) => {
    const postDate = new Date(post.created_at);
    return (
      postDate >= fiveDaysAgo &&
      postDate <= today &&
      post.blog_id !== blog?.blog_id
    );
  });

  if (loading) return <CircularProgress />;
  if (error) return <Typography>Error: {error}</Typography>;

  return (
    <>
      <Header />
      <Grid container spacing={2} sx={{ px: { xs: 4, md: 4, lg: 14 } }}>
        <Grid item xs={12} md={12}>
          <Typography
            sx={{
              fontSize: "35px",
              fontFamily: "Ubuntu",
              fontWeight: 500,
              pt: 6,
              textAlign: "center",
            }}
          >
            {blog?.blog_header}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          sx={{ mt: 5, border: "1px solid lightgray", borderRadius: 5 }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontFamily: "Ubuntu",
              color: "#396A62",
              py: 2,
              textAlign: "center",
              fontWeight: 500,
            }}
          >
            {new Date(blog?.created_at).toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })}
          </Typography>
          <Box sx={{ textAlign: "center" }}>
            <img
              src={`data:image/png;base64,${blog?.file_data}`}
              alt={blog?.blog_header}
              style={{ width: "50%" }}
            />
          </Box>
          <Box sx={{ textAlign: "left", p: 2, fontFamily: "Ubuntu" }}>
            <div
              dangerouslySetInnerHTML={{ __html: blog?.blog_content }}
              style={{ fontSize: "16px", fontFamily: "Ubuntu" }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={4} sx={{ pt: "40px !important" }}>
          <Box sx={{ backgroundColor: "#E6F1EF", borderRadius: 4 }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontFamily: "Ubuntu",
                fontWeight: 600,
                pt: 2,
                textAlign: "center",
              }}
            >
              Recent Blogs
            </Typography>
            {filteredRecentPosts.length > 0 ? (
              <Box sx={{ p: 2 }}>
                {filteredRecentPosts.map((post, index) => (
                  <Box key={index} sx={{ mb: 2, textAlign: "left" }}>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <img
                        src={`data:image/png;base64,${post.file_data}`}
                        alt={post.blog_header}
                        style={{ width: "20%", borderRadius: "5px" }}
                      />
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontFamily: "Ubuntu",
                          fontWeight: 500,
                          textAlign: "left",
                        }}
                      >
                        {post.blog_header}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography>No recent posts available.</Typography>
            )}
            <Box sx={{ display: "flex", py: 4, gap: 1 }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontFamily: "Ubuntu",
                  textAlign: "left",
                  fontWeight: 600,
                  pl: 1,
                }}
              >
                Follow us on:
              </Typography>
              <Box
                sx={{ display: "flex", alignItems: "center", mt: 0, gap: 1 }}
              >
                <a
                  href="https://www.facebook.com/arhamshare"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Facebook} alt="Facebook" style={{ width: 40 }} />
                </a>
                <a
                  href="https://www.instagram.com/arhamshare/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Instagram} alt="Instagram" style={{ width: 40 }} />
                </a>
                <a
                  href="https://www.youtube.com/@ArhamShare"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={YouTube} alt="YouTube" style={{ width: 40 }} />
                </a>
                <a
                  href="https://www.linkedin.com/in/arham-share/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={LinkedIn} alt="LinkedIn" style={{ width: 40 }} />
                </a>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Fotter />
    </>
  );
}

export default Details;
