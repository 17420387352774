import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import Header from "../Component/HomePage/Header";
import Fotter from "../Component/Footer";
import Download from "./Download";

function Index() {
  const navigate = useNavigate();

  const handleBreadcrumbClick = (event, path) => {
    event.preventDefault();
    navigate(path);
  };

  return (
    <>
      <Header />

      <Grid
        container
        sx={{
          background:
            "linear-gradient(180deg, #ffffff 0%, #c1d8d4 0%, #ffffff 40%)",
          px: { xs: 4, md: 20 },
        }}
      >
        <Grid container pt={6}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ margin: "auto 0", textAlign: "center" }}
          >
            <Typography
              sx={{
                fontSize: { md: "50px", xs: "35px" },

                fontFamily: "Ubuntu",
                fontWeight: 500,
                pb: 4,
                // textAlign: "left",
              }}
            >
              Downloads
            </Typography>
            {/* <Breadcrumbs
              aria-label="breadcrumb"
              separator=">"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Link
                color="inherit"
                href="/"
                onClick={(event) => handleBreadcrumbClick(event, "/")}
                sx={{
                  cursor: "pointer",
                  textDecoration: "none",
                  fontFamily: "Ubuntu",
                  fontWeight: 500,
                  fontSize: "25px",
                  color: "#3D3D3D",
                }}
              >
                Home
              </Link>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontWeight: 400,
                  fontSize: "25px",
                  color: "#3D3D3D",
                }}
              >
                Download
              </Typography>
            </Breadcrumbs> */}
          </Grid>
        </Grid>
        <Download />
      </Grid>
      <Fotter />
    </>
  );
}

export default Index;
