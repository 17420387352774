import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  Card,
  CardMedia,
  CardContent,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import Unicom from "../assets/images/IPOs/UniCom.png";

function IposDetails() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedOption, setSelectedOption] = useState("MAINLINE");
  const [ipoData, setIpoData] = useState([]);
  const [filteredIpoData, setFilteredIpoData] = useState([]);
  const [status, setStatus] = useState("");

  // Define titles for each tab
  const tabTitles = ["Upcoming IPO", "Open IPO", "Closed IPO", "Listed IPO"];

  const handleTabChange = (newValue) => {
    setSelectedTab(newValue);
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  
  useEffect(() => {
    const apiUrl =
      "https://web.arhamshare.com/ipo/v1/get-ipo-details?source=new_arham";

    const conditions = ["upcoming", "current", "closed"];
    const condition = conditions[selectedTab];

    axios
      .post(apiUrl, { condition })
      .then((response) => {
        if (response.data.status === "success") {
          setIpoData(response.data.data);
        } else {
          console.error("Failed to fetch IPO data");
        }
      })
      .catch((error) => {
        console.error("Error fetching IPO data:", error);
      });
  }, [selectedTab]);

  useEffect(() => {
    const filteredData = ipoData.filter(
      (ipo) => ipo.ipo_type === selectedOption
    );
    setFilteredIpoData(filteredData);
  }, [selectedOption, ipoData]);

  useEffect(() => {
    const statuses = ["Upcoming", "Open", "Closed", "Listed"];
    setStatus(statuses[selectedTab]);
  }, [selectedTab]);

  return (
    <div>
      <Grid container pt={6} spacing={2} sx={{ px: { xs: 4, md: 4, lg: 14 } }}>
        <Grid item xs={6} md={10}>
          <Typography
            variant="h4"
            sx={{ fontWeight: "bold", fontFamily: "Ubuntu", textAlign: "left" ,fontSize:{xs:"22px",md:"30px"}}}
          >
            {tabTitles[selectedTab]}
          </Typography>
        </Grid>

        <Grid item xs={12} md={12}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Grid container spacing={2}>
                {["Upcoming", "Open", "Closed"].map((label, index) => (
                  <Grid item key={index}>
                    <Button
                      variant="contained"
                      onClick={() => handleTabChange(index)}
                      sx={{
                        backgroundColor:
                          selectedTab === index ? "#E0ECEA" : "transparent",
                        color: "#396A62",
                        textTransform: "none",
                        borderRadius: "4px",
                        border:
                          selectedTab === index
                            ? "1px solid #396A62"
                            : "1px solid transparent",
                        fontWeight: 600,
                        boxShadow: "none",
                        "&:hover": {
                          backgroundColor: "#E0ECEA",
                          borderColor: "#396A62",
                        },
                      }}
                    >
                      {label}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item>
              <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                <Select
                  value={selectedOption}
                  onChange={handleSelectChange}
                  size="small"
                  sx={{
                    color: "#396A62",
                    fontFamily: "Ubuntu",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#396A62",
                      borderRadius: "10px",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#396A62",
                    },
                    "& .MuiSelect-icon": {
                      color: "#396A62",
                    },
                  }}
                >
                  <MenuItem value="MAINLINE">Mainboard</MenuItem>
                  <MenuItem value="SME">SME IPO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography
            sx={{
              fontFamily: "Ubuntu",
              textAlign: "left",
              color: "#3D3D3D",
            }}
          >
            Exciting IPO opportunities waiting to be picked! Apply Now!
          </Typography>
        </Grid>

        <Grid container spacing={3} py={4}>
          {filteredIpoData.map((ipo, index) => (
            <Grid item xs={12} md={12} key={index}>
              <Card sx={{ p: 4 }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    {/* <CardMedia
                      component="img"
                      sx={{ width: 140, height: 140 }}
                      image={Unicom}
                      alt={ipo.name}
                    /> */}
                    <CardContent>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          fontFamily: "Ubuntu",
                          textAlign: "left",
                        }}
                      >
                        {ipo.name}
                      </Typography>
                    </CardContent>
                  </Grid>

                  <Grid item xs={12} md={2} sx={{ textAlign: "left" }}>
                    <Typography sx={{ fontFamily: "Ubuntu", pt: 2 }}>
                      Price Range
                    </Typography>
                    <Typography sx={{ fontFamily: "Ubuntu", fontWeight: 600 }}>
                      ₹{ipo.minprice} - ₹{ipo.cutoffprice}
                    </Typography>
                    <Typography sx={{ fontFamily: "Ubuntu", pt: 2 }}>
                      Issue Size
                    </Typography>
                    <Typography sx={{ fontFamily: "Ubuntu", fontWeight: 600 }}>
                      ₹{ipo.issuesize}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} md={3} sx={{ textAlign: "left" }}>
                    <Typography sx={{ fontFamily: "Ubuntu", pt: 2 }}>
                      Open - Close
                    </Typography>
                    <Typography sx={{ fontFamily: "Ubuntu", fontWeight: 600 }}>
                      {ipo.biddingstartdate} - {ipo.biddingenddate}
                    </Typography>
                    <Typography sx={{ fontFamily: "Ubuntu", pt: 2 }}>
                      Min Investment
                    </Typography>
                    <Typography sx={{ fontFamily: "Ubuntu", fontWeight: 600 }}>
                      {ipo.minbidquantity * ipo.cutoffprice}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} md={2} sx={{ textAlign: "left" }}>
                    <Typography sx={{ fontFamily: "Ubuntu", pt: 2 }}>
                      Status
                    </Typography>
                    <Typography sx={{ fontFamily: "Ubuntu", fontWeight: 600 }}>
                      {status}
                    </Typography>
                    {/* <Typography sx={{ fontFamily: "Ubuntu", pt: 2 }}>
                      Allocation Date
                    </Typography>
                    <Typography sx={{ fontFamily: "Ubuntu", fontWeight: 600 }}>
                      {ipo.biddingenddate}
                    </Typography> */}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
}

export default IposDetails;
