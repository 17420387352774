import React, { useState } from "react";
import { Box, Button, Card, Grid, TextField, Typography, Alert } from "@mui/material";
import Phone from "../assets/images/Contact-Us/phone.png";
import Mail from "../assets/images/Contact-Us/mail.png";
import Document from "../assets/images/Contact-Us/document.png";
import { useFormik } from "formik";
import * as Yup from "yup";

function Enquire() {
  const [submitStatus, setSubmitStatus] = useState("");

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .matches(/^\d{10}$/, "Phone number must be 10 digits")
      .required("Phone number is required"),
    address: Yup.string().required("Address is required"),
    briefDescription: Yup.string().required("Description is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      address: "",
      briefDescription: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/arham_web/v1/contactus/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              name: values.name,
              email: values.email,
              phone_no: values.phoneNumber,
              address: values.address,
              desc: values.briefDescription,
            }),
          }
        );

        const result = await response.json();

        if (response.ok) {
          setSubmitStatus("success");
          formik.resetForm();
        } else {
          setSubmitStatus("fail");
          console.error("Failed to submit form:", result.message);
        }
      } catch (error) {
        setSubmitStatus("fail");
        console.error("Error submitting form:", error);
      }
    },
  });

  return (
    <Grid container spacing={2} pt={4}>
      <Grid item xs={12} md={6}>
        <Typography
          sx={{
            fontSize: {md:"28px",xs:"22px"},
            fontFamily: "Ubuntu",
            fontWeight: 500,
            textAlign: "left",
            pt: { xs: 2, md: 6 },
          }}
        >
          Still have something stuck in your mind?
          <br />
          <span style={{ color: "#396A62" }}>
            Don’t mind to contact us right away,
          </span>
        </Typography>

        <Grid container spacing={2} sx={{ pt: 6 }}>
          {/* Call Us at */}
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                backgroundColor: "#EAF3F2",
                p: 2,
                display: "flex",
                flexDirection: "row",
                borderRadius: 1,
              }}
            >
              <Box sx={{ position: "relative", bottom: "30px" }}>
                <img src={Phone} alt="Phone" style={{ width: "50px" }} />
              </Box>
              <Box sx={{ pl: 2 }}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    textAlign: "left",
                    fontFamily: "Ubuntu",
                  }}
                >
                  Call us at
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    textAlign: "left",
                    fontFamily: "Ubuntu",
                  }}
                >
                  Timing 09:00 AM To 6:00 PM
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    textAlign: "left",
                    color: "#396A62",
                    fontFamily: "Ubuntu",
                    fontWeight: 600,
                  }}
                >
                  0261 - 6794000
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Email us at */}
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                backgroundColor: "#EAF3F2",
                p: 2,
                display: "flex",
                flexDirection: "row",
                borderRadius: 1,
              }}
            >
              <Box sx={{ position: "relative", bottom: "30px" }}>
                <img src={Mail} alt="Mail" style={{ width: "50px" }} />
              </Box>

              <Box sx={{ pl: 1 }}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    textAlign: "left",
                    fontFamily: "Ubuntu",
                    pt: 1,
                  }}
                >
                  Email us at
                </Typography>

                <Typography
                  sx={{
                    fontSize: "14px",
                    textAlign: "left",
                    color: "#396A62",
                    fontFamily: "Ubuntu",
                    fontWeight: 600,
                    pt: 1,
                  }}
                >
                  contact@arhamshare.com
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Need to send any important document */}
          <Grid item xs={12} sm={12} sx={{ paddingTop: "35px !important" }}>
            <Box
              sx={{
                backgroundColor: "#EAF3F2",
                p: 2,
                display: "flex",
                borderRadius: 1,
              }}
            >
              <Box sx={{ position: "relative", bottom: "30px" }}>
                <img src={Document} alt="Document" style={{ width: "50px" }} />
              </Box>

              <Box sx={{ pl: 1, textAlign: "left" }}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontFamily: "Ubuntu",
                  }}
                >
                  Need to send any important document,
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      backgroundColor: "#396A62",
                      "&:hover": {
                        backgroundColor: "#0B2A29",
                      },
                      textTransform: "none",
                      fontFamily: "Ubuntu",
                      borderRadius: "5px",
                      fontSize: "large",
                      height: "30px",
                    }}
                  >
                    <span style={{ fontSize: "15px" }}>Courier us at -</span>
                  </Button>
                </Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#396A62",
                    fontFamily: "Ubuntu",
                    fontWeight: 600,
                    pt: 1,
                  }}
                >
                  601-606, Central Business Hub, Parle Point, Surat, Gujarat -
                  395 007.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {/* Right Section (Get in Touch Form) */}
      <Grid
        item
        xs={12}
        md={6}
        lg={5}
        sx={{
          paddingLeft: { md: "100px !important" },
          paddingTop: { md: "50px !important" },
        }}
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 2,
            backgroundColor: "white",
            border: "1px solid white",
            borderRadius: "20px",
          }}
        >
          <Typography
            sx={{
              fontSize: "22px",
              textAlign: "center",
              color: "#396A62",
              fontFamily: "Ubuntu",
              fontWeight: 500,
              pb: 1,
            }}
          >
            Get in touch
          </Typography>

          {/* Show success or failure message */}
          {submitStatus === "success" && (
            <Alert severity="success" sx={{ width: "100%", mb: 2 }}>
              Form submitted successfully!
            </Alert>
          )}
          {submitStatus === "fail" && (
            <Alert severity="error" sx={{ width: "100%", mb: 2 }}>
              Failed to submit the form. Please try again later.
            </Alert>
          )}

          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  InputLabelProps={{
                    style: {
                      fontFamily: "Ubuntu",
                      color: "black",
                    },
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: "8px",
                      fontFamily: "Ubuntu",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  InputLabelProps={{
                    style: {
                      fontFamily: "Ubuntu",
                      color: "black",
                    },
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: "8px",
                      fontFamily: "Ubuntu",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="phoneNumber"
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                  InputLabelProps={{
                    style: {
                      fontFamily: "Ubuntu",
                      color: "black",
                    },
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: "8px",
                      fontFamily: "Ubuntu",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="address"
                  label="Address"
                  variant="outlined"
                  fullWidth
                  size="small"
                  multiline
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.address && Boolean(formik.errors.address)}
                  helperText={formik.touched.address && formik.errors.address}
                  InputLabelProps={{
                    style: {
                      fontFamily: "Ubuntu",
                      color: "black",
                    },
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: "8px",
                      fontFamily: "Ubuntu",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="briefDescription"
                  label="Brief Description"
                  variant="outlined"
                  fullWidth
                  size="small"
                  multiline
                  rows={3}
                  value={formik.values.briefDescription}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.briefDescription &&
                    Boolean(formik.errors.briefDescription)
                  }
                  helperText={
                    formik.touched.briefDescription &&
                    formik.errors.briefDescription
                  }
                  InputLabelProps={{
                    style: {
                      fontFamily: "Ubuntu",
                      color: "black",
                    },
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: "8px",
                      fontFamily: "Ubuntu",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    backgroundColor: "#396A62",
                    "&:hover": {
                      backgroundColor: "#0B2A29",
                    },
                    textTransform: "none",
                    fontFamily: "Ubuntu",
                    borderRadius: "8px",
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Enquire;
