import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

function ShareTable() {
  const [selectedOption, setSelectedOption] = useState("2024");

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // Sample data for the table rows
  const tableData = [
    {
      companyName: "VSL Finance Ltd",
      recordDate: "Aug 26, 2024",
      issueOpen: "Aug 26, 2024",
      issueClose: "-",
      buybackType: "Tender Offer",
      buybackPrice: 380,
      issueSize: 125.4,
    },
    {
      companyName: "VSL Finance Ltd",
      recordDate: "Aug 26, 2024",
      issueOpen: "Aug 26, 2024",
      issueClose: "-",
      buybackType: "Tender Offer",
      buybackPrice: 380,
      issueSize: 125.4,
    },
    {
      companyName: "VSL Finance Ltd",
      recordDate: "Aug 26, 2024",
      issueOpen: "Aug 26, 2024",
      issueClose: "-",
      buybackType: "Tender Offer",
      buybackPrice: 380,
      issueSize: 125.4,
    },
    {
      companyName: "VSL Finance Ltd",
      recordDate: "Aug 26, 2024",
      issueOpen: "Aug 26, 2024",
      issueClose: "-",
      buybackType: "Tender Offer",
      buybackPrice: 380,
      issueSize: 125.4,
    },
    {
      companyName: "VSL Finance Ltd",
      recordDate: "Aug 26, 2024",
      issueOpen: "Aug 26, 2024",
      issueClose: "-",
      buybackType: "Tender Offer",
      buybackPrice: 380,
      issueSize: 125.4,
    },
    {
      companyName: "VSL Finance Ltd",
      recordDate: "Aug 26, 2024",
      issueOpen: "Aug 26, 2024",
      issueClose: "-",
      buybackType: "Tender Offer",
      buybackPrice: 380,
      issueSize: 125.4,
    },
    {
      companyName: "VSL Finance Ltd",
      recordDate: "Aug 26, 2024",
      issueOpen: "Aug 26, 2024",
      issueClose: "-",
      buybackType: "Tender Offer",
      buybackPrice: 380,
      issueSize: 125.4,
    },
    // Add more data rows as needed
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Box sx={{ pt: 8 }}>
          <Typography
            sx={{
              fontSize: { md: "42px", xs: "25px" },
              fontWeight: 600,
              textAlign: "left",
              color: "#396A62",
              fontFamily: "Ubuntu",
              pt: { md: 4 },
            }}
          >
            Buyback Of Shares
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} md={10}>
        <Typography
          sx={{
            textAlign: "left",
            fontSize: { md: "13px", xs: "13px" },
            fontFamily: "Ubuntu",
            color: "#3D3D3D",
            pt: 1,
          }}
        >
          When public companies profit significantly, they often pay back the
          excess earnings to shareholders by paying dividends. But another way
          for profitable firms to reward investors is a buyback of shares. Delve
          deeper to explore in detail the concept of share buybacks.
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <FormControl variant="outlined" sx={{ minWidth: 120 }}>
          <Select
            value={selectedOption}
            onChange={handleSelectChange}
            size="small"
            sx={{
              color: "#396A62",
              fontFamily: "Ubuntu",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#396A62",
                borderRadius: "10px",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#396A62",
              },
              "& .MuiSelect-icon": {
                color: "#396A62",
              },
            }}
          >
            <MenuItem value="2024">2024</MenuItem>
            {/* Additional options can be added here */}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={12}>
        <Box
          sx={{
            overflowX: "auto", 
          }}
        >
          <Table
            sx={{
              borderCollapse: "separate",
              borderSpacing: "0",
              border: "1px solid #396A62", 
              borderRadius: "10px", 
              minWidth: "650px",
              overflow:"hidden"
            }}
          >
            <TableHead style={{ backgroundColor: "#396A62" }}>
              <TableRow>
                <TableCell style={{ color: "white", textAlign: "center" }}>
                  Company Name
                </TableCell>
                <TableCell style={{ color: "white", textAlign: "center" }}>
                  Record Date
                </TableCell>
                <TableCell style={{ color: "white", textAlign: "center" }}>
                  Issue Open
                </TableCell>
                <TableCell style={{ color: "white", textAlign: "center" }}>
                  Issue Close
                </TableCell>
                <TableCell style={{ color: "white", textAlign: "center" }}>
                  Buyback Type
                </TableCell>
                <TableCell style={{ color: "white", textAlign: "center" }}>
                  Buyback Price (Per Share)
                </TableCell>
                <TableCell style={{ color: "white", textAlign: "center" }}>
                  Issue Size-Shares(Cr)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ textAlign: "center" }}>
                    {row.companyName}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {row.recordDate}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {row.issueOpen}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {row.issueClose}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {row.buybackType}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {row.buybackPrice}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {row.issueSize}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ShareTable;
