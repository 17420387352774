import { Breadcrumbs, Grid, Link, Typography, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import Header from "../Component/HomePage/Header";
import Footer from "../Component/Footer";

function NotFoundPage() {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <>
      <Header />

      <Grid
        container
        sx={{
          background:
            "linear-gradient(180deg, #ffffff 0%, #c1d8d4 0%, #ffffff 50%)",
          px: { xs: 4, md: 4, lg: 14 },
        }}
      >
        <Grid container justifyContent="center">
          <Grid item xs={12} md={12} textAlign="center">
            <Typography
              sx={{
                fontSize: { xs: "150px", md: "250px" },
                fontFamily: "Ubuntu",
                fontWeight: 900,
                color: "#D8E4E2",
                mb: 0,
              }}
            >
              404
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "18px", md: "28px" },
                fontFamily: "Ubuntu",
                fontWeight: 500,
                color: "#000",
                mb: 1,
              }}
            >
              It seems that the page you're looking <br />
              for is missing
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "Ubuntu",
                fontWeight: 400,
                color: "#3D3D3D",
                mb: 2,
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
              purus <br />
              sit amet luctus venenatis lectus magna fringilla.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "#396A62",
                "&:hover": {
                  backgroundColor: "#0B2A29",
                },
                textTransform: "none",
                fontFamily: "Ubuntu",
                border: "1px #0B2A29",
                borderRadius: "5px",
                fontSize: "large",
                width: { md: "20%" },
                // height: "50px",
              }}
              className="header-btn"
              onClick={handleGoHome}
            >
              <span style={{ fontSize: "15px" }}>Go to homepage</span>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default NotFoundPage;
