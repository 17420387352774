import React from "react";
import Header from "../Component/HomePage/Header";
import { Button, Grid, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import IPOs from "../assets/images/IPOs/IPO.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import IposDetails from "./IposDetails";
import Fotter from "../Component/Footer";

const Index = () => {
  return (
    <>
      <Header />

      <Grid
        container
        sx={{
          background:
            " linear-gradient(180deg, #ffffff 0%, #c1d8d4 0%, #ffffff 100%)",
          px: { xs: 4, md: 4, lg: 14 },
        }}
      >
        <Grid container pt={6} spacing={4}>
          <Grid item xs={12} md={6} sx={{ margin: "auto 0" }}>
            <Typography
              sx={{
                fontSize: { md: "50px", xs: "35px" },
                fontFamily: "Ubuntu",
                fontWeight: 500,
                // pt: 4,
                textAlign: "left",
              }}
            >
              Apply for latest <br />
              IPOs instantly
            </Typography>
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                pt: 2,
                textAlign: "left",
              }}
            >
              Track all Mainboard and SME IPOs easily. Apply online with your
              <br />
              Arham share account instantly via UPI.
            </Typography>
            <Grid sx={{ display: "flex", pt: 1 }}>
              <Button
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIcon sx={{ fontSize: "30px" }} />}
                sx={{
                  backgroundColor: "#396A62",
                  "&:hover": {
                    backgroundColor: "#0B2A29",
                  },
                  fontFamily: "Ubuntu",
                  textTransform: "none",
                  border: "1px #0B2A29",
                  borderRadius: "8px",
                  height: "38px",
                }}
                className="header-btn"
                type="submit"
              >
                <span style={{ fontSize: "13px" }}>Apply for an IPO</span>
              </Button>
            </Grid>
            <Typography
              align="left"
              style={{
                fontWeight: "400",
                fontFamily: "Ubuntu",
                paddingTop: "10px",
              }}
            >
              <span style={{ color: "#3D3D3D" }}>Don't have an account?</span>
              <span
                style={{
                  color: "#396A62",
                  cursor: "pointer",
                  fontWeight: "bold",
                  paddingLeft: "5px ",
                }}
              >
                Sign Up
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={IPOs} style={{ width: "60%" }} />
          </Grid>
        </Grid>
      </Grid>
        <IposDetails />
      <Fotter />
    </>
  );
};

export default Index;
