import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useRef, useState } from "react";
import male from "../../assets/images/Icon/male.png";
import woman from "../../assets/images/Icon/woman.png";
import ArrowDown from "../../assets/images/Icon/ArrowDown.png";
import ArrowGreen from "../../assets/images/Icon/ArrowGreen.png";
import Vector from "../../assets/images/Vector.png";
import NSE from "../../assets/images/logo/NSE.png";
import MCX from "../../assets/images/logo/MCX.png";
import CDSL from "../../assets/images/logo/CDSL.png";
import BSE from "../../assets/images/logo/BSE.png";

import Star from "../../assets/images/Star.png";
import rating from "../../assets/images/logo/rating.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../style/style.css";

function Journey() {
  const sliderRef = useRef(null);
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonials = [
    {
      name: "Kabir Sharma",
      text: "I have been trading with Arham Share from past 4 months. Their client service has been excellent. My friend suggested to me Arham Share, I switched and it was one of the best decision I ever made.",
      gender: "male",
    },
    {
      name: "Ankit Patel",
      text: "Stock market was new to me but with the help of trado app, I was able to understand trading easily. My friend suggested to me Arham Share, I switched and it was one of the best decision I ever made.",
      gender: "male",
    },
    {
      name: "Sejal Jain",
      text: "I have been trading in Arham Share’s application from past three months, the customer service is pretty positive and excellent environment at the office.",
      gender: "female",
    },
    {
      name: "Neha Dhanuka",
      text: "I would suggest everyone to open their demat account in Arham Share, cause this is one of the best place to start your investment journey.",
      gender: "female",
    },
    {
      name: "Karan Jiyaviya",
      text: "Great stock trading company in surat gujarat most trusted and valuable company. And friendly sported staff.",
      gender: "male",
    },
    {
      name: "Foram Dadawala",
      text: "Account opening process at Arham was easy and after that their service was cherry on a pie.",
      gender: "female",
    },
    {
      name: "Lokesh Panpaliya",
      text: "Arham share provides exceptional stock brokerage services with knowledgeable staff, excellent customer service, and a user-friendly platform, Transparency and Trust.I always feel confident that my investments are in safe hands..",
      gender: "male",
    },
    {
      name: "Divya kambalan",
      text: "Best application for trading and investment. The back office support was excellent . I got every report I wanted in instance. Excellent application overall.",
      gender: "female",
    },
    {
      name: "Hiral Gandhi",
      text: "Arhamshare offers its clients a wide range of different segments where we can invest our money! Great application to use .",
      gender: "female",
    },
    {
      name: "kalpana Bhatt",
      text: "I opened an account over here and the opening process went very smoothly without any problem.",
      gender: "male",
    },
  ];

  // Settings for the react-slick slider
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentSlide(index),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const timelineItems = [
    {
      year: "2010",
      description: "Marks the establishment year of Arham Share.",
      backgroundColor: "#E4F0EE",
      textColor: "#2D5A53",
    },
    {
      year: "2012",
      description: "Registered as a member of NSE and BSE.",
      backgroundColor: "#E3EBC1",
      textColor: "#363E14",
    },
    {
      year: "2013",
      description: "Become a registered member of MCX.",
      backgroundColor: "#E4F0EE",
      textColor: "#2D5A53",
    },
    {
      year: "2022",
      description: "Launched discount broking product ‘TradeCircle’.",
      backgroundColor: "#E3EBC1",
      textColor: "#363E14",
    },

    {
      year: "2022",
      description: "Within a Span of 3 months we added 11K clients",
      backgroundColor: "#E4F0EE",
      textColor: "#2D5A53",
    },
    {
      year: "2022",
      description: "300+ Authorized partners across India.",
      backgroundColor: "#E3EBC1",
      textColor: "#363E14",
    },
    {
      year: "2023",
      description:
        "With a total of more than 45K clients covering 3000+ pin codes with branches in Gujarat and Maharashtra.",
      backgroundColor: "#E4F0EE",
      textColor: "#2D5A53",
    },
    {
      year: "2023",
      description: "800+ terminals running smoothly.",
      backgroundColor: "#E3EBC1",
      textColor: "#363E14",
    },
    {
      year: "2024",
      description: "Introduced our new trading platform ‘Trado’.",
      backgroundColor: "#E4F0EE",
      textColor: "#2D5A53",
    },
  ];

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrevious = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div>
      <Grid
        container
        sx={{
          px: { xs: 4, md: 6, lg: 4 },
          py: { xs: 2, md: 4, lg: 6 },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontSize: "40px",
                    fontWeight: 500,
                    fontFamily: "Ubuntu",
                    mt: 4,
                  }}
                >
                  <span className="perk-font"> Our Chapters & Milestones</span>
                </Typography>
              </Grid>
            </Grid>
            <Slider
              ref={sliderRef}
              {...sliderSettings}
              className="timeline-slider"
            >
              {timelineItems.map((item, index) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: "4px",
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: item.backgroundColor,
                      width: "74%",
                      border: `1px solid ${item.backgroundColor}`,
                      borderRadius: "10px",
                      fontSize: "25px",
                      color: item.textColor,
                      fontWeight: 600,
                      mt: 4,
                    }}
                  >
                    {item.year}
                  </Button>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        borderTop: "2px dashed #000",
                        width: "100%",
                        position: "absolute",
                        left: "50%",
                        transform: "translateX(-50%)",
                        top: "10px",
                        zIndex: -1,
                      }}
                    ></div>
                    <img
                      src={index % 2 === 0 ? ArrowDown : ArrowGreen}
                      style={{ width: "20px" }}
                      alt="Arrow"
                    />
                  </div>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontFamily: "Ubuntu",
                      fontSize: { md: "15px", xs: "18px", sm: "20px" },
                      textAlign: "center",
                    }}
                  >
                    {item.description}
                  </Typography>
                </div>
              ))}
            </Slider>
            <Grid container justifyContent="center" sx={{ mt: 4 }}>
              <Button
                onClick={handlePrevious}
                sx={{
                  backgroundColor: currentSlide === 0 ? "#E0E0E0" : "#2D5A53",
                  color: "#fff",
                  borderRadius: "50%",
                  minWidth: "40px",
                  height: "40px",
                  mx: 2,
                  "&:hover": {
                    backgroundColor: "#639a91",
                  },
                }}
                disabled={currentSlide === 0}
              >
                &larr;
              </Button>
              <Button
                onClick={handleNext}
                sx={{
                  backgroundColor: (
                    isSmallScreen
                      ? currentSlide === timelineItems.length - 1
                      : currentSlide === timelineItems.length - 4
                  )
                    ? "#E0E0E0"
                    : "#2D5A53",

                  color: "#fff",
                  borderRadius: "50%",
                  minWidth: "40px",
                  height: "40px",
                  mx: 2,
                  "&:hover": {
                    backgroundColor: "#639a91",
                  },
                }}
                disabled={
                  isSmallScreen
                    ? currentSlide === timelineItems.length - 1
                    : currentSlide === timelineItems.length - 4
                }
              >
                &rarr;
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12} md={12} xs={12} lg={12}>
        <Typography>
          <Container>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "30px",
                fontFamily: "Ubuntu",
                color: "#4E4E4E",
              }}
            >
              {/* <span className="reliable-font reliable-font-min">
                Our Reliable Trusted Exchange
              </span> */}
            </Typography>
            <Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Grid container sx={{ pr: { md: 10 },pb:3 }}>
                  <Grid item xs={6} md={3}>
                    <div className="logo-container">
                      <img
                        src={BSE}
                        alt="Logo"
                        style={{
                          height: "90px",
                          width: "120px",
                        }}
                        className="logo-img bse-img"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div className="logo-container">
                      <img
                        src={NSE}
                        className="logo-img nse-img"
                        alt="Logo"
                        style={{
                          paddingTop: "35px",
                          width: "140px",
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Box>
                      <div className="logo-container">
                        <img
                          src={MCX}
                          className="logo-img mcx-img"
                          alt="Logo"
                          style={{
                            paddingTop: "45px",
                            width: "120px",
                          }}
                        />
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Box>
                      <div className="logo-container">
                        <img
                          src={CDSL}
                          className="logo-img cdsl-img"
                          alt="Logo"
                          style={{
                            paddingTop: "35px",
                            width: "150px",
                          }}
                        />
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Typography>
      </Grid>

      <Grid
        container
        sx={{
          px: { xs: 4, md: 20 },
        }}
      >
        <Grid
          item
          sm={12}
          md={12}
          xs={12}
          sx={{
            backgroundColor: "#EDF0E1",
            mt: 4,
            border: "1px solid #EDF0E1",
            borderRadius: "50px",
            p: 4,
          }}
        >
          <Grid container>
            <Grid item xs={12} md={12}>
              <Grid container spacing={2} sx={{ py: 2 }}>
                <Grid item xs={12} md={4} sm={12}>
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: { md: "45px", xs: "23px" },
                        textAlign: "left",
                        fontFamily: "Ubuntu",
                      }}
                    >
                      <span>What Our Clients Say</span>
                    </Typography>
                    <Box>
                      {/* <Box sx={{ display: "flex", pt: 2 }}>
                        <img
                          src={Vector}
                          style={{
                            width: "50px",
                            height: "50px",
                          }}
                        />
                        <Typography
                          sx={{
                            fontWeight: "500",
                            fontSize: "35px",
                            fontFamily: "Ubuntu",
                          }}
                        >
                          <span className="cudo-font">Trustpilot</span>
                        </Typography>
                      </Box> */}
                      <Grid item xs={2} lg={2} md={6} sx={{ pt: 4 }}>
                        <img src={Star} />
                      </Grid>
                    </Box>

                    <Typography
                      sx={{
                        fontSize: "13px",
                        pt: 4,
                        textAlign: "left",
                        fontFamily: "Ubuntu",
                      }}
                    >
                      4.7 out of 5 based on 227 reviews
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} md={8} sm={12}>
                  <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                      <Box key={index}>
                        <Box
                          sx={{
                            padding: "20px",
                            backgroundColor: "white",
                            border: "1px solid white",
                            borderRadius: "10px",
                            margin: "0 10px",
                            height: "250px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box>
                            <Box sx={{ display: "flex", pt: 2 }}>
                              <img src={rating} alt="Rating" />
                            </Box>
                            <Typography
                              sx={{
                                textAlign: "left",
                                pt: 2,
                                fontFamily: "Ubuntu",
                                fontSize: "14px",
                                fontWeight: 400,
                                color: "#3D3D3D",
                                height: "120px",
                                overflow: "hidden",
                              }}
                            >
                              {testimonial.text}
                            </Typography>
                          </Box>

                          <Grid item xs={12} md={12}>
                            <Box sx={{ display: "flex", gap: "15px", pt: 2 }}>
                              <Box
                                sx={{
                                  width: "50px",
                                  height: "50px",
                                  backgroundColor: "#f0f0f0",
                                  borderRadius: "50%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={
                                    testimonial.gender === "male" ? male : woman
                                  }
                                  alt={testimonial.gender}
                                  style={{ width: "30px", height: "30px" }}
                                />
                              </Box>
                              <Typography
                                sx={{
                                  fontSize: "18px",
                                  fontWeight: 600,
                                  pt: 2,
                                  fontFamily: "Ubuntu",
                                }}
                              >
                                {testimonial.name}
                              </Typography>
                            </Box>
                          </Grid>
                        </Box>
                      </Box>
                    ))}
                  </Slider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Journey;
