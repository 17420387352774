import { Box, Card, Container, Grid, Typography, Link } from "@mui/material";
import React from "react";
import NSE from "../../assets/images/logo/NSE.png";
import MCX from "../../assets/images/logo/MCX.png";
import CDSL from "../../assets/images/logo/CDSL.png";
import Mobile from "../../assets/images/Mobile.png";
import Laptop from "../../assets/images/Laptop.png";
import GooglePay from "../../assets/images/GooglePay.png";
import AppStore from "../../assets/images/AppStore.png";
import BSE from "../../assets/images/logo/BSE.png";
import Stock from "../../assets/images/Icon/Stock.png";
import FutureOption from "../../assets/images/Icon/FutureOption.png";
import Currency from "../../assets/images/Icon/Currency.png";
import Commodity from "../../assets/images/Icon/Commodity .png";
import Mutual from "../../assets/images/Icon/Mutual.png";
import Lending from "../../assets/images/Icon/Lending.png";
import Insurance from "../../assets/images/Icon/Insurance.png";
import Security from "../../assets/images/Icon/Security.png";
import Mark from "../../assets/images/logo/TrueMark.png";
import AnimatedBox from "./AnimatedBox";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../style/style.css";
import { motion } from "framer-motion";

function Index() {
  const MotionBox = motion(Box);

  return (
    <Grid
      container
      sx={{
        px: { xs: 4, md: 20 },
      }}
    >
      <Grid container sx={{ pt: {md:18 ,xs:5,sm:10} }} spacing={2}>
        <Grid
          item
          xs={12}
          md={4}
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          <motion.img
            src={Mobile}
            alt="Mobile App"
            style={{
              width: "450px",
              height: "450px",
            }}
            className="media-img"
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.9 }}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ ml: { xs: 0, md: 20 } }}>
          <Box sx={{ textAlign: "left", fontFamily: "Ubuntu" }}>
            <Typography
              sx={{
                fontSize: "65px",
                fontWeight: "bold",
                fontFamily: "Ubuntu",
              
              }}
            >
              <span className="web-font">Trado</span>
            </Typography>
            <Typography
              sx={{ fontSize: "35px", fontFamily: "Ubuntu", color: "gray" }}
            >
              <span className="app-font"> The Mobile Application</span>
            </Typography>
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                pt: 1,
                fontSize: 15,
                color: "#3D3D3D",
                lineHeight: "30.3px",
              }}
            >
              <span className="text-line-height">
                Trade like a pro with our Trado App. Trado App has all the
                features covered in one place. Our mobile application is crafted
                in such a way that anyone can execute their trades at lightning
                fast speed.
              </span>
            </Typography>

            <Box sx={{ pt: 2 }}>
              {[
                "Advanced Analytics",
                "Simple User Interface",
                "Actionlist",
                "Option Chain",
                "Trade Across All Segments",
              ].map((text, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    gap: "18px",
                    mb: 2,
                  }}
                >
                  <img src={Mark} alt="Mark" />
                  <Typography sx={{ fontFamily: "Ubuntu", fontWeight: 400 }}>
                    {text}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box sx={{ display: "flex", gap: "4px" }}>
              <Link href="https://bit.ly/000AND" underline="none">
                <img
                  src={GooglePay}
                  style={{ height: "40px", width: "auto", cursor: "pointer" }}
                  alt="Google Pay"
                />
              </Link>
              <Link href="https://bit.ly/000IOS">
                <img
                  src={AppStore}
                  style={{ height: "40px", width: "auto", cursor: "pointer" }}
                />
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container sx={{ pt: { md: 10 } }} spacing={4}>
        <Grid item xs={12} md={6} sx={{ margin: "auto 0" }}>
          <Box sx={{ textAlign: "left" }}>
            <Typography
              sx={{
                fontSize: "65px",
                fontWeight: "bold",
                fontFamily: "Ubuntu",
              }}
            >
              <span className="web-font">Web Trader</span>
            </Typography>
            <Typography
              sx={{ fontSize: "35px", fontFamily: "Ubuntu", color: "gray" }}
            >
              <span className="app-font"> Desktop Trading Platform</span>
            </Typography>
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                pt: 1,
                fontSize: 14,
                color: "#3D3D3D",
                lineHeight: "30.3px",
              }}
            >
              <span className="text-line-height">
                Stay connected, even when you're away from your mobile device.
                Our Web Trader allows you to trade instantly from your desktop
                or laptop. Experience seamless trading convenience with our
                user-friendly web platform.
              </span>
            </Typography>

            <Box sx={{ pt: 2 }}>
              {[
                "Everything At One Screen",
                "Create Your Own Playlist Of Stocks",
                "Technical Indicators",
              ].map((text, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    gap: "18px",
                    mb: 2,
                  }}
                >
                  <motion.img
                    src={Mark}
                    alt="Mark"
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.3 }}
                  />
                  <Typography sx={{ fontFamily: "Ubuntu", fontWeight: 400 }}>
                    {text}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <Box>
            <motion.img
              src={Laptop}
              alt="Laptop App"
              style={{
                width: "520px",
                height: "340px",
              }}
              className="media-img"
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.9 }}
            />
          </Box>
        </Grid>
      </Grid>
      {/* <AnimatedBox
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        delay={0.2}
      > */}
      <Grid
        container
        // spacing={2}
        sx={{
          backgroundColor: "#E4F0EE",
          mt: 12,
          border: "1px solid #E4F0EE",
          borderRadius: "50px",
          p: 4,
        }}
      >
        <Grid item xs={12}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "35px",
              fontFamily: "Ubuntu",
              color: "black",
              textAlign: "center",
            }}
          >
            Our Products
          </Typography>
        </Grid>

        <Grid container spacing={2} sx={{ pt: 4 }}>
          {[
            { src: Stock, alt: "Stocks & IPOs", title: "Stocks & IPOs" },
            {
              src: FutureOption,
              alt: "Futures and Options",
              title: "Futures and Options",
            },
            {
              src: Currency,
              alt: "Currency Derivatives",
              title: "Currency Derivatives",
            },
            {
              src: Commodity,
              alt: "Commodity Derivatives",
              title: "Commodity Derivatives",
            },
            { src: Mutual, alt: "Mutual Funds", title: "Mutual Funds" },
            {
              src: Lending,
              alt: "Stock Lending Borrowing (SLB)",
              title: "Stock Lending Borrowing (SLB)",
            },
            { src: Insurance, alt: "Insurance", title: "Insurance" },
            {
              src: Security,
              alt: "Bonds and Govt. Securities",
              title: "Bonds and Govt. Securities",
            },
          ].map((product, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": {
                    transform: "scale(1.05)",
                    // boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                <img src={product.src} alt={product.alt} height="50px" />
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "18px",
                    fontFamily: "Ubuntu",
                    pl: 2,
                    textAlign: "left",
                  }}
                >
                  {product.title}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {/* </AnimatedBox> */}
    </Grid>
  );
}

export default Index;
