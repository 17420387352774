import React, { useState } from "react";
import { Grid, TextField, Button, Typography, Tooltip } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

const SignUp = ({ onModeChange }) => {
  const [otpSent, setOtpSent] = useState(false);

  const formik = useFormik({
    initialValues: {
      panNumber: "",
      otp: "",
    },
    validationSchema: Yup.object({
      panNumber: Yup.string().required("PAN number is required"),
      otp: Yup.string().when("otpSent", {
        is: true,
        then: Yup.string().required("OTP is required"),
      }),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const handleSendOtp = () => {
    if (formik.values.panNumber === "") {
      formik.setFieldTouched("panNumber", true);
      return;
    }
    setOtpSent(true);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid item xs={12} md={9} sx={{ pt: 2 }} lg={10}>
        <TextField
          name="panNumber"
          label="Enter PAN Number"
          variant="outlined"
          fullWidth
          size="small"
          id="outlined-size-small"
          value={formik.values.panNumber}
          onChange={formik.handleChange}
          error={formik.touched.panNumber && Boolean(formik.errors.panNumber)}
          helperText={formik.touched.panNumber && formik.errors.panNumber}
          InputLabelProps={{
            style: {
              fontFamily: "Ubuntu",
            },
          }}
          InputProps={{
            sx: {
              borderRadius: "13px",
              fontFamily: "Ubuntu",
            },
          }}
        />
      </Grid>

      {otpSent && (
        <Grid item xs={12} md={9} sx={{ pt: 2 }} lg={10}>
          <TextField
            name="otp"
            label="Enter OTP"
            variant="outlined"
            fullWidth
            size="small"
            id="outlined-size-small"
            value={formik.values.otp}
            onChange={formik.handleChange}
            error={formik.touched.otp && Boolean(formik.errors.otp)}
            helperText={formik.touched.otp && formik.errors.otp}
            InputLabelProps={{
              style: {
                fontFamily: "Ubuntu",
              },
            }}
            InputProps={{
              sx: {
                borderRadius: "13px",
                fontFamily: "Ubuntu",
              },
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} md={10} lg={12} sx={{ display: "flex" }}>
        <Typography
          sx={{
            fontSize: "12px",
            pt: 2,
            textAlign: "left",
            fontFamily: "Ubuntu",
            color: "#939393",
          }}
        >
          You will receive an OTP on your PAN registered number{" "}
        </Typography>
      </Grid>

      <Grid item xs={12} md={4} sm={12} lg={6} sx={{ pt: 2, display: "flex" }}>
        <Tooltip title="Send OTP">
          <Button
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: "#396A62",
              "&:hover": {
                backgroundColor: "#0B2A29",
              },
              textTransform: "none",
              fontFamily: "Ubuntu",
              border: "1px #0B2A29",
              borderRadius: "10px",
              fontSize: "large",
              width: "60%",
              height: "50px",
            }}
            onClick={handleSendOtp}
          >
            <span style={{ fontSize: "15px" }}>Send OTP</span>
          </Button>
        </Tooltip>
      </Grid>

      <Grid item xs={12} md={12} lg={12} sx={{ pt: 1 }}>
        <Typography
          align="left"
          color="#939393"
          style={{ fontWeight: "400", fontFamily: "Ubuntu" }}
        >
          <span style={{ fontWeight: "500" }}>Already have an account?</span>
          <span
            style={{
              color: "#396A62",
              cursor: "pointer",
              fontWeight: "bold",
              paddingLeft: "5px ",
            }}
            onClick={onModeChange}
          >
            Sign In
          </span>
        </Typography>
      </Grid>
    </form>
  );
};

export default SignUp;
